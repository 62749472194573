<template>
    <div class="tools-content-01">
        <div class="title" v-if="$mobile"><img src="../../assets/images/mobile/t-content01.png" /> </div>
        <div class="title" v-else><img src="../../assets/images/pc/t-content01.png" /> </div>
        <div class="train">
            <img src="../../assets/images/pc/train.png" />
        </div>
        <div class="content-01">
            <div class="content">
                <div class="token-info">
                    <div class="icon">
                        <img :src="$store.state.currentPool.icon" />
                    </div>
                    <h2>{{$t('home.mining',[$store.state.currentPool.tokenCode])}}</h2>
                    <div class="tips">{{$t('tools.earning')}}</div>
                    <div class="amount">{{formatValue($store.state.rewardAmount, 5)}} {{$store.state.currentPool.tokenCode}}</div>
                    <div class="history-button">
                        <span @click="$router.push('/history')">{{$t('tools.history')}}</span>
                    </div>
                </div>
                <div class="line-02"><span /><span /></div>
                <pool-count-down />
                <div class="line-02" style="height: 180px"><span /><span /></div>
            </div>
        </div>
    </div>
</template>

<script>
    import {ADDRESS, ChainName} from "../../config/constant";
import toolsMixin from "../../mixins/toolsMixin";
import poolCountDown from '../common/pool-coundown'
export default {
    name: 'ContentTools01',
    mixins: [toolsMixin],
    components: {
        poolCountDown
    },
    data() {
        return {
            symbol: '',
            amount: ''
        }
    },
    watch: {
        // '$store.state.chainId'() {
        //     // eslint-disable-next-line no-debugger
        //     // console.log(this.$store.state.checkChainId, 'this.$store.state.checkChainId')
        //     // debugger
        //     if (!this.$store.state.checkChainId) {
        //         return
        //     }
        //     this.rewarder()
        // },
        '$store.state.poolData'() {
            if (!this.$store.state.checkChainId) {
                return
            }
            this.rewarder()
        }
    },
    mounted() {
        if (this.$store.state.chainId && this.$store.state.checkChainId && this.$store.state.poolData.length > 0) {
            this.rewarder()
        }
    },
    methods: {
        rewarder() {
            const stingPoolRewarder = this.$vueweb3.contract(this.$abi.StingPoolRewarder, ADDRESS[ChainName[this.$store.state.chainId]].stakingpoolrewarder(this.$store.state.currentPool.id)).methods
            stingPoolRewarder.rewardToken().call().then(result => {
                this.$store.commit('setRewardTokenAddress', result)
                const token = this.$vueweb3.contract(this.$abi.ERC20, result).methods
                token.symbol().call().then(result => {
                    this.symbol = result
                    this.$store.commit('setSymbol', result)
                })
            })
        }
    }
}
</script>

<style lang="less">

</style>
