export default {
    menu: {
        home: 'Home',
        marktplace: 'Marktplace',
        myTools: 'My Tools',
        connect: 'Connect Wallet'
    },
    home: {
        mining: '{0} MINING',
        apr: 'Earn up to {0}% APR',
        countStart: 'Mining Period Starts In',
        countEnd: 'Mining Period Ends In',
        tipInfo: 'Buy NFT tools and Stake NFT tools to mine {0} tokens',
        preparingTime: 'Preparing time',
        preparingTimeDate: '15:00 on 19 Jul 2021 - 18 Aug 2021 (UTC+8)',
        preparingTimeDate1: '15:00 on 18 Aug 2021',
        campaignPeriod: 'Campaign period',
        campaignPeriodDate: '00:00 on 23 Jul 2021 - 00:00 on 21 Aug 2021 (UTC+8)',
        campaignPeriodDate1: '15:00 on 22 Aug 2021 - 15:00 on 20 Sep 2021 (UTC+8)',
        remainRarity: 'Remain/Rarity',
        price: 'Price<br /><span>(100% Rebate after 60 Days)</span>',
        after: '(100% Rebate after 60 Days)',
        rebateDate: 'MNC can be rebated after',
        buy: 'BUY',
        soldOut: 'SOLD OUT',
        days: 'days',
        youBuy: 'You are about to buy',
        buyed: 'Congrat!<br />You bought {0} ',
        stakeContent: 'Stake your NFT tools any time during the campaign period, <br />receive reward every day!',
        aprText: 'APR up to',
        approve: 'Approve',
        confirm: 'Confirm',
        stakeNow: 'Stake Now',
        hotToWorks: '<p>Metaminer is a virtual world built on blockchain technology where users can manage their on-chain assets through collecting mining tools.</p><p>All the NFT tools in metaminers universe has its ability to mine different hot tokens in the market.</p>',
        getNFT: 'Get NFT',
        result: 'After 60 days...<br />(result)',
        rebate: 'MNC 100% rebate',
        join: 'Join Campaigns',
        ownership: 'NFT ownership',
        rewardsText: 'Rewards based on<br />Mining Days & Tools Features...<br />(result)',
        rewards: 'Rewards',
        current: 'Current',
        content3: '<p>Up to {0}% APR</p><p>{1}</p>',
        comingSoon: 'Coming Soon',
        yapr: '<p>Next Campaign:</p><p>Bitcoin Cash</p>',
        stakeLater: 'Stake Later',
        stakeNowApprove: 'Stake Approve',
        balanceText: 'Available MNC',
        completed: 'Completed'
    },
    tools: {
        earning: 'EARNING',
        miningRewards: 'Mining Rewards',
        mining: 'Mining',
        apr: 'APR',
        amount: 'Amount',
        claim: 'Claim',
        toolsInfo: 'Tools Info',
        rarity: 'Rarity',
        price: 'Price (Rebatable)',
        empty: 'No data',
        empty1: '<p>Your tools box is empty,</p><p>all tools are used for mining!</p>',
        empty2: '<p>Your tools box is empty,</p><p>buy NFT tools to start mining now!</p>',
        buyNow: 'Buy Now',
        empty3: '<p>Stake your NFT tools</p><p>to start mining now!</p>',
        empty4: '<p>Buy NFT tools</p><p>to start mining now!</p>',
        purchaseBlock: 'Block',
        toolType: 'Type',
        beRebated: 'Rebate',
        stake: 'Stake',
        unStake: 'UnStake',
        rebatableAmount: 'Total Rebatable Amount',
        rebate: 'Rebate',
        rebateText: 'Rebate is not available, buy NFT tools and get rebate after 60 days!',
        stakingTime: 'Staking isn’t start yet, please come back later',
        stakingTimeBtn: 'Check starting time',
        history: 'Previous Events'
    },
    common: {
        'Whac-A-Mole': 'Whac-A-Mole',
        'Mjolnir': 'Mjolnir',
        'Rocket Drill': 'Rocket Drill',
        back: 'back'
    },
    bridge: {
        bridge: 'Bridge',
        menu: {
            home: 'Home',
            marktplace: 'Marktplace',
            myTools: 'My Tools',
            connect: 'Connect Wallet'
        },
        balance: 'Balance',
        max: 'MAX',
        from: 'From',
        to: 'To',
        transfer: 'TRANSFER',
        withdraw: 'Withdraw',
        amount: 'Amount',
        progress: 'Progress',
        checkingText: 'Checking the amount of {0} on {1}',
        next: 'NEXT',
        transferAmount: 'Transfer Amount',
        transactionFee: 'Transaction fee',
        confirm: 'Confirm',
        approve: 'Approve',
        loading: 'LOADING',
        transferCompleted: 'Transfer Completed',
        transferringText: 'Transferring {0} MNC from <b>{1}</b> to <b>{2}</b>',
        pleaseWait: 'This may take few minutes, please wait',
        transferedText: 'Transferred {0} MNC from {1} to {2}',
        dialogTitle: {
            1: 'Preparing your transfer request',
            2: 'Awaiting confirmations on chain',
            3: 'Completing your transfer',
            4: 'Finalizing your transfer'
        }
    }
}
