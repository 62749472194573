import Vue from 'vue'
import pcHome from '../pages/pc/home/index.vue'
import pcTools from '../pages/pc/tools/index.vue'
import mobilecHome from '../pages/mobile/home/index.vue'
import mobileTools from '../pages/mobile/tools/index.vue'
import pcHistoryTools from '../pages/pc/tools/history.vue'
import mobileHistoryTools from '../pages/mobile/tools/history.vue'
import pcBridge from '../pages/pc/bridge/index.vue'
import mobileBridge from '../pages/mobile/bridge/index.vue'

let mobile = false
if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    mobile = true
}
Vue.prototype.$mobile = mobile

let Home = null ,Tools = null, Bridge = null, HistoryTools
if (!mobile) {
    Home = pcHome
    Tools = pcTools
    Bridge = pcBridge
    HistoryTools = pcHistoryTools
} else {
    Home = mobilecHome
    Tools = mobileTools
    Bridge = mobileBridge
    HistoryTools = mobileHistoryTools
}

const routes = {
    routes: [
        { path: '/', component: Home },
        { path: '/tools', component: Tools },
        { path: '/history', component: HistoryTools },
        { path: '/bridge', component: Bridge }
    ]
}

export default routes
