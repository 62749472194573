<template>
    <div class="history-pools-container">
        <div class="history-bar">
            <div class="back-button" @click="$router.back()">
                <i class="el-icon-arrow-left"></i><span>{{$t('menu.myTools')}}</span>
            </div>
        </div>
        <div class="history-content" v-if="cpd_list.length > 0">
            <div class="history-item" v-for="(item, index) in cpd_list" :key="index">
                <div class="logo"><img :src="item.host + item.image_url" /> </div>
                <div class="content">
                    <div class="sub-item">
                        <label>{{$t('tools.toolType')}}</label>
                        <div class="name">{{item.name}}</div>
                    </div>
                    <div class="sub-item">
                        <label>{{$t('tools.price')}}</label>
                        <div class="amount">{{item.mncAmount}}</div>
                    </div>
                    <div class="sub-item">
                        <label>{{$t('tools.amount')}}</label>
                        <div class="amount">{{item.stakeAmount}}</div>
                    </div>
                    <div class="sub-item">
                        <label>{{$t('tools.amount')}}</label>
                        <div class="amount">{{formatValue(item.reward, 6)}} {{item.symbol}}</div>
                    </div>
                </div>
                <div class="buttons">
                    <el-button :disabled="item.unstakeloading || item.stakeAmount == 0" :loading="item.unstakeloading" @click="unStake(item, index)">{{$t('tools.unStake')}}</el-button>
                    <el-button :disabled="item.rewardsloading || item.reward == 0" :loading="item.rewardsloading" @click="redeemRewards(item, index)">{{$t('tools.claim')}}</el-button>
                </div>
            </div>
        </div>
        <div class="empty" v-else>
            {{$t('tools.empty')}}
        </div>
    </div>
</template>

<script>
import {ADDRESS, ChainName, INITDATA} from "../../config/constant"
import MncNFTStore from "../../config/abi/MncNFTStore"
import {HOST} from "../../config/api"
// import BigNumber from "bignumber.js"

// BigNumber.config({ EXPONENTIAL_AT: 1e+9 })

export default {
    name: 'HistoryTools',
    data() {
      return {
          historyTools: []
      }
    },
    computed: {
      cpd_list() {
          console.log(this.historyTools, 22222)
          return this.historyTools
      }
    },
    watch: {
        '$store.state.poolData'() {
            if (!this.$store.state.checkChainId) {
                return
            }
            this.init()
        }
    },
    mounted() {
        if (this.$store.state.chainId && this.$store.state.checkChainId && this.$store.state.poolData.length > 0) {
            this.init()
        }
    },
    methods: {
        init() {
            const array = []
            for(let key in INITDATA) {
                array.push(this.getData(INITDATA[key]))
            }
            console.log(array, 'array')
            Promise.all(array).then(data => {
                const historyPools = []
                console.log(this.$store.state.poolData, 'this.$store.state.poolData')
                this.$store.state.poolData.forEach(pool => {
                    if (pool.type !== 'default' && pool.type !== 'current') {
                        const toolData = JSON.parse(JSON.stringify(data))
                        historyPools.push(this.initTools(toolData, pool))
                    }
                })
                Promise.all(historyPools).then(result => {
                    const historyList = []
                    console.log(result, 98989898)
                    this.$store.commit('setLoading', false)
                    result.forEach(poolData => {
                        poolData.forEach(item => {
                            if (item.stakeAmount * 1 > 0 || item.reward * 1 > 0) {
                                historyList.push(item)
                            }
                        })
                    })
                    this.historyTools = historyList
                })
            })
        },
        initTools(data, pool) {
            console.log(pool, 9999)
            const pools = []
            data.forEach(item => {
                item.host = HOST
                item.poolId = pool.id
                item.symbol = pool.tokenCode
                pools.push(this.getPoolsData(item, pool))
            })
            return Promise.all(pools).then(toolsData => {
                return Promise.all(toolsData.map(item => {
                    return this.getProductInfos(item)
                })).then(toolsList => {
                    return toolsList.map(item => {
                        return item
                    })
                })
            })
        },
        getData(url) {
            return this.$ajax((new Date().getTime()) + '', {
                url: HOST + url
            })
        },
        getPoolsData(item, pool) {
            const chainId = this.$store.state.chainId
            const id = pool.id
            const stakingPools = this.$vueweb3.contract(this.$abi.StakingPools, ADDRESS[ChainName[chainId]].stakingpools(id)).methods
            return stakingPools.userData(item.id, this.$store.state.account).call().then(result => {
                item.stakeAmount =  this.$web3.utils.fromWei(result.stakeAmount, 'ether')
                return stakingPools.getReward(item.id, this.$store.state.account).call().then(result => {
                    item.reward = this.$web3.utils.fromWei(result, 'ether')
                    return item
                })
            })
        },
        getProductInfos(item) {
            const mncNFTStore = this.$vueweb3.contract(MncNFTStore, ADDRESS[ChainName[this.$store.state.chainId]].mncnftstore).methods
            return mncNFTStore.productInfos(item.id).call({form: this.$store.state.account}).then (result => {
                const staking = this.$web3.utils.fromWei(result.amountPerNFT + '', 'ether')
                item.mncAmount = staking
                item.amountPerNFT = result.amountPerNFT
                item.expireBlock = result.expireBlock
                item.nftTypeId = result.nftTypeId
                item.publishBlock = result.publishBlock
                item.rebatePercentage = result.rebatePercentage
                item.rebateWaitBlock = result.rebateWaitBlock
                item.token = result.token
                return item
            })
        },
        redeemRewards(item, index) {
            item.rewardsloading = true
            this.$set(this.historyTools, index, item)
            const stakingPools = this.$vueweb3.contract(this.$abi.StakingPools, ADDRESS[ChainName[this.$store.state.chainId]].stakingpools(item.poolId)).methods
            stakingPools.redeemRewards(item.id).send({from: this.$store.state.account}).then(result => {
                item.rewardsloading = false
                if (result.status) {
                    this.init()
                }
            }).catch((err) => {
                console.log(err)
                item.rewardsloading = false
                this.$set(this.historyTools, index, item)
            })
        },
        unStake(item, index) {
            const id = item.id
            item.unstakeloading = true
            this.$set(this.historyTools, index, item)
            const amount = item.stakeAmount + ''
            const stakingPools = this.$vueweb3.contract(this.$abi.StakingPools, ADDRESS[ChainName[this.$store.state.chainId]].stakingpools(item.poolId)).methods
            stakingPools.unstake(id, this.$web3.utils.toWei(amount, 'ether')).send({from: this.$store.state.account, gas: '210000'}).then(result => {
                this.getTransactionReceipt(result.transactionHash, () => {
                    // item.unstakeloading = false
                    this.init()
                })
            }).catch(err => {
                console.log(err)
                item.unstakeloading = false
                this.$set(this.historyTools, index, item)
            })
        },
        formatValue(val, decimal) {
            const value = this.$bigNumber(val)
            return  value.toFixed(decimal)
        },
        getTransactionReceipt(hash, fn) {
            setTimeout(() => {
                this.$web3.eth.getTransactionReceipt(hash).then((result) => {
                    console.log(result, hash)
                    if (result && result.status) {
                        fn()
                    } else {
                        this.getTransactionReceipt(hash, fn)
                    }
                })
            }, 5 * 3 * 1000)
        }
    }
}
</script>
