<template>
    <div class="count-down">
        <div class="tip">{{tipText}}</div>
        <div class="time">
            <coundown :status="-1" :targetTime="Date.now() + time" @stop="stop" />
        </div>
        <div class="time-tip">
            <span>Days</span>
            <span>Hours</span>
            <span>Minutes</span>
            <span>Seconds</span>
        </div>
    </div>
</template>

<script>
    import { blockToTime } from '../../utils'

    export default {
        name: 'PoolCountDown',
        data() {
            return {
                time: 0,
                tipText: this.$t('home.countStart')
            }
        },
        watch: {
            '$store.state.endTime'(v) {
                this.init(v)
            },
            '$i18n.locale'() {
                const v = this.$store.state.endTime
                this.init(v)
            },
            // '$store.state.chainId'() {
            //     if (!this.$store.state.checkChainId) {
            //         return
            //     }
            //     this.getPoolData()
            // },
            '$store.state.poolData'() {
                if (!this.$store.state.checkChainId) {
                    return
                }
                this.getPoolData()
            }
        },
        created() {
            if (this.$store.state.chainId && this.$store.state.checkChainId && this.$store.state.poolData.length > 0) {
                this.getPoolData()
            }
        },
        methods: {
            init(v) {
                if (v < 0) {
                    this.tipText = this.$t('home.countStart')
                    this.time = blockToTime(-v)
                    return
                }
                if (v > 0) {
                    this.tipText = this.$t('home.countEnd')
                    this.time = blockToTime(v)
                    return
                }
                this.time = 0
            },
            getPoolData() {
                const currentPool = this.$store.state.currentPool
                const startBlock = currentPool.startBlock * 1
                const endBlock = currentPool.endBlock * 1
                let num = this.$store.state.currentBlock
                num = num * 1
                let endTime = 0
                if (startBlock > num) {
                    endTime = num - startBlock
                } else {
                    if (num < endBlock) {
                        endTime = endBlock - num
                    }
                }
                console.log(endTime, 'endTime')
                this.$store.commit('setEndTime', endTime)
                this.init(endTime)
            },
            stop() {
                this.getPoolData()
            }
        }
    }
</script>
