<template>
    <div class="home-content-01">
        <div class="title" v-if="$mobile"><img src="../../assets/images/mobile/content01.png" /> </div>
        <div class="title" v-else><img src="../../assets/images/pc/content01.png" /> </div>
        <div class="left-button" v-if="$mobile" @click="onLeft"><img src="../../assets/images/pc/left.png" /></div>
        <div class="right-button" v-if="$mobile" @click="onRight"><img src="../../assets/images/pc/left.png" /></div>
        <div class="content">
            <div v-if="$mobile" class="content-bg"></div>
            <div class="carousel">
                <div class="carousel-content" :class="carousel">
                    <div class="info-item" v-for="(item, index) in tools" :key="index" :class="{disabled: item.expireBlock - item.blockNumber <= 0}">
                        <div class="icon">
                            <img :src="item.host + item.image_url" />
                            <img v-if="item.expireBlock - item.blockNumber < 0" src="../../assets/images/pc/sold-out.png" class="sold-out" />
                        </div>
                        <div class="name">{{setName(item)}}</div>
                        <div class="star"><span v-for="(s, i) in item.level" :key="i"> * </span></div>
                        <div class="item scarcity">
                            <label>{{$t('home.remainRarity')}}</label>
                            <span>{{item.scarcity}}/{{item.totalCount}}</span>
                        </div>
                        <div class="item staking">
                            <label v-html="$t('home.price')"></label>
                            <span>{{convert(item.staking)}}MNC</span>
                        </div>
                        <div class="item locking">
                            <label>{{$t('home.rebateDate')}}</label>
                            <span>60 {{$t('home.days')}}</span>
                        </div>
                        <div class="item count">
                            <count v-model="item.buyCount" :count="item.buyCount" :max="item.scarcity * 1" />
                        </div>
                        <div class="button">
                            <el-button v-if="item.expireBlock - item.blockNumber < 0" disabled>{{$t('home.soldOut')}}</el-button>
                            <el-button v-else :disabled="item.buyCount === 0 || item.disabledBuy || item.disabled" @click="gotoBuy(item)">{{$t('home.buy')}}</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
                :visible.sync="dialogVisible"
                width="320px"
                :before-close="handleClose">
            <template slot="title">
                <span class="el-dialog__title" v-if="buyed" v-html="$t('home.buyed', [stakeCount]) + setName(cpd_product)"></span>
                <span class="el-dialog__title" v-else>{{$t('home.youBuy')}}</span>
            </template>
            <div class="home-product-dialog">
                <div class="icon">
                    <img :src="cpd_product.host + cpd_product.image_url" />
                    <div class="buy-count" v-if="!buyed">x{{cpd_product.buyCount}}</div>
                </div>
                <div class="buy-content" v-if="!buyed">
                    <div class="name">{{setName(cpd_product)}}</div>
                    <div class="star"><span v-for="(s, i) in cpd_product.level" :key="i"> * </span></div>
                    <div class="stc">{{convert(cpd_product.staking * cpd_product.buyCount)}} MNC</div>
                    <div class="tip">{{$t('home.after')}}</div>
                    <div class="balance">可用 MNC: {{formatValue($store.state.mncBalance, 4)}}</div>
                </div>
                <div class="stake-content" v-else>
                    <div v-html="$t('home.stakeContent')"></div>
                    <div class="apr">
                        {{$t('home.aprText')}}<br />
                        <b>{{setApr(cpd_product)}}%</b>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <template v-if="!approve">
                    <div class="dialog-button">
                        <el-button class="approve" :disabled="approveLoading || checkBuy($store.state.mncBalance, cpd_product)" :loading="approveLoading" @click="setApprove(cpd_product)">{{$t('home.approve')}}</el-button>
                    </div>
                </template>
                <template v-else>
                    <div class="dialog-button" v-if="!buyed">
                        <el-button :disabled="buyloading || checkBuy($store.state.mncBalance, cpd_product)" :loading="buyloading" @click="buy(cpd_product)">{{$t('home.confirm')}}</el-button>
                    </div>
                    <template v-else>
                        <div class="dialog-button" v-if="!stakeApprove">
                            <el-button class="approve" :disabled="stakeloading" :loading="stakeloading" @click="stakeApproveFunc">{{$t('home.stakeNowApprove')}}</el-button>
                        </div>
                        <div class="dialog-button" v-else>
                            <el-button v-if="cpd_product.stakingNow" class="stake" :disabled="stakeloading" :loading="stakeloading" @click="stake">{{$t('home.stakeNow')}}</el-button>
                        </div>
                    </template>
                    <div class="dialog-button" v-if="buyed">
                        <el-button class="stake" @click="dialogVisible = false">{{$t('home.stakeLater')}}</el-button>
                    </div>
                </template>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {ADDRESS, ChainName} from '../../config/constant'
import toolsMixin from '../../mixins/toolsMixin'
// import BigNumber from 'bignumber.js'

export default {
    name: 'Content01',
    mixins: [toolsMixin],
    data() {
        return {
            dialogVisible: false,
            check: 0,
            approve: false,
            stakeApprove: false,
            approveLoading: false,
            buyloading: false,
            stakeloading: false,
            buyed: false,
            carousel: 'carousel-01',
            count: 1,
            stakeCount: ''
        }
    },
    computed: {
        cpd_product() {
            if (this.check) {
                return this.tools.filter(item => item.id === this.check)[0]
            }
            return {}
        }
    },
    watch: {
        account(v) {
            if (v) {
                this.getBalance()
            }
        },
        '$store.state.poolData'() {
            if (!this.$store.state.checkChainId && !this.$store.state.checkChainId) {
                return
            }
            this.initTools().then(() => {
                this.loopData()
            })
        }
    },
    mounted() {
        if (this.$store.state.chainId && this.$store.state.checkChainId && this.$store.state.poolData.length > 0) {
            this.initTools().then(() => {
                this.loopData()
            })
        }
    },
    methods: {
        getBalance() {
            const mockERC20 = this.$vueweb3.contract(this.$abi.MockERC20,  ADDRESS[ChainName[this.$store.state.chainId]].erc20mock).methods
            const account = this.$store.state.account
            mockERC20.balanceOf(account).call({}, (error, result) => {
                const ethBalance = this.$web3.utils.fromWei(result, "ether")
                const balance = parseFloat(ethBalance).toFixed(4)
                // console.log(balance, 123, error)
                this.$store.commit('setBalance', balance)
            })
        },
        handleClose() {
            this.dialogVisible = false
            this.buyed = false
        },
        gotoBuy(item) {
            this.dialogVisible = true
            this.check = item.id
            const amount = this.$web3.utils.toWei(this.$bigNumber(item.buyCount).times(item.staking).toString())
            const mockERC20 = this.$vueweb3.contract(this.$abi.MockERC20,  ADDRESS[ChainName[this.$store.state.chainId]].erc20mock).methods
            const nft = this.$vueweb3.contract(this.$abi.MinersNFTs, ADDRESS[ChainName[this.$store.state.chainId]].minersnfts).methods
            this.checkingApproveNFt(nft, amount, this.$store.state.account).then(res => {
                this.stakeApprove = res
            })
            mockERC20.allowance(this.account, ADDRESS[ChainName[this.$store.state.chainId]].mncnftstore).call({}).then(result => {
                if (result < amount) {
                    this.approve = false
                } else {
                    this.approve = true
                }
            })
        },
        setApprove(item) {
            const amount = this.$bigNumber(item.buyCount).times(item.staking).toString()
            if (this.$store.state.mncBalance < amount * 1) {
                this.$message.error('Insufficient Balance')
                return
            }
            this.approveLoading = true
            const mockERC20 = this.$vueweb3.contract(this.$abi.MockERC20,  ADDRESS[ChainName[this.$store.state.chainId]].erc20mock).methods
            this.checkingApproveMnc(mockERC20, this.$web3.utils.toWei(amount), ADDRESS[ChainName[this.$store.state.chainId]].mncnftstore).then((res) => {
                this.approve = true
                this.approveLoading = false
            }).catch(err => {
                console.log(err)
                this.approveLoading = false
            })
        },
        buy(item) {
            this.buyloading = true
            this.buyStore(item)
        },
        buyStore(item) {
            const count = item.buyCount
            const amount = this.$web3.utils.toWei(this.$bigNumber(count).times(item.staking).toString())
            const mncNFTStore = this.$vueweb3.contract(this.$abi.MncNFTStore, ADDRESS[ChainName[this.$store.state.chainId]].mncnftstore).methods
            // console.log(amount, 111)
            mncNFTStore.getNFT(this.check, amount).send({from: this.account}).then(result => {
                this.getTransactionReceipt(result.transactionHash, () => {
                    this.buyed = true
                    this.buyloading = false
                    this.stakeCount = count
                    this.initTools().then(() => {
                        this.loopData()
                    })
                })
            }).catch(err => {
                console.log(err)
                this.buyloading = false
            })
        },
        stakeApproveFunc() {
            this.stakeloading = true
            const amount = this.stakeCount + ''
            const minersNFTs = this.$vueweb3.contract(this.$abi.MinersNFTs, ADDRESS[ChainName[this.$store.state.chainId]].minersnfts).methods
            minersNFTs.setApprovalForAll(ADDRESS[ChainName[this.$store.state.chainId]].stakingpools(this.$store.state.currentPool.id), this.$web3.utils.toWei(amount, 'ether')).send({from: this.$store.state.account}).then(res => {
                this.stakeApprove = true
                this.stakeloading = false
            })
        },
        stake() {
            this.stakeloading = true
            const amount = this.stakeCount + ''
            const stakingPools = this.$vueweb3.contract(this.$abi.StakingPools, ADDRESS[ChainName[this.$store.state.chainId]].stakingpools(this.$store.state.currentPool.id)).methods
            stakingPools.stake(this.check, this.$web3.utils.toWei(amount, 'ether')).send({from: this.account, gas: '2100000'}).then(result => {
                this.getTransactionReceipt(result.transactionHash, () => {
                    this.stakeloading = false
                    this.stakeCount = 0
                    this.$router.push('/tools')
                })
            })
        },
        onLeft() {
            if (this.count <= 1) {
                return
            }
            this.count -= 1
            this.carousel = 'carousel-0' + this.count
        },
        onRight() {
            if (this.count >= 3) {
                return
            }
            this.count += 1
            this.carousel = 'carousel-0' + this.count
        },
        checkBuy(balance, item) {
            let amount = this.$bigNumber(item.buyCount)
            const totalBalance = amount.times(item.staking).toString()
            const num = this.$bigNumber(balance)
            return num.minus(totalBalance).toString()*1 < 0
        }
    }
}
</script>
