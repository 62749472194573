import Vue from 'vue'
import App from './App.vue'
import store from './stores'
import i18n from './config/lang'
import router from './router'
import './config/abi'
import './utils'
import './utils/web3'
import './plugins'
import './config/api'
import './components'
import BigNumber from "bignumber.js"

BigNumber.config({ EXPONENTIAL_AT: 1e+9 })

Vue.component('MLayout', () => import('./layout/mobile'))
Vue.component('PCLayout', () => import('./layout/pc'))

Vue.config.productionTip = false

Vue.prototype.$bigNumber = (v) => {
  v = v || 0
  return new BigNumber(v)
}

// const VConsole = require('vconsole')
// new VConsole()

new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
