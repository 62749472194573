<script>
import {ADDRESS, ChainName, poolLogos, INITDATA, netWork} from "./config/constant"
import Web3 from "web3"

let loading = null

export default {
    name: 'app',
    watch: {
        '$store.state.chainId'(v, n) {
            this.getPoolData(v)
        },
        '$store.state.loading'(v) {
            if (v) {
                loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                    customClass: 'main-loading'
                })
            } else {
                if (loading) {
                    loading.close()
                }
            }
        }
    },
    created() {
      // console.log(this.$web3.currentProvider.chainId, 'this.$web3.currentProvider')
        if (this.$web3.currentProvider) {
            this.$store.commit('setChainId', this.$web3.currentProvider.chainId)
            // this.getPoolData(this.$web3.currentProvider.chainId)
        }
    },
    methods: {
        getPoolData(chainId) {
            this.$store.commit('setLoading', true)
            const pools = ADDRESS[ChainName[chainId]].staking
            console.log(chainId, 'getPoolData',pools)
            const id = Object.keys(INITDATA)[Object.keys(INITDATA).length - 1]
            // const promises = pools.map((item) => {
            //     const chainId = this.$store.state.chainId
            //     console.log(item.POOL, id, '99', chainId, this.$web3.currentProvider.chainId)
            //     const stakingPools = this.$vueweb3.contract(this.$abi.StakingPools, item.POOL).methods
            //     return stakingPools.poolInfos(id).call()
            // })
            let chainName = ChainName[this.$store.state.chainId]
            this.$ajax('getBlockNumber', {
                url: netWork[chainName].rpcUrls[0],
                method: 'post',
                data: {
                    id: new Date().getTime(),
                    method: 'eth_blockNumber',
                    jsonrpc: '2.0',
                    params: []
                }
            }).then(res => {
                const num = this.$web3.utils.hexToNumberString(res.result)
                this.$store.commit('setCurrentBlock', num)
                this.getPoolInfo(id, pools, 0, pools.length, [], num)
            })
        },
        getPoolInfo(id, pools, index, len, list, num, current = 0) {
            const stakingPools = this.$vueweb3.contract(this.$abi.StakingPools, pools[index].POOL).methods
            stakingPools.poolInfos(id).call().then(res => {
                let type = 'next'
                if (res.endBlock * 1 > num * 1 && res.startBlock * 1 < num * 1) {
                    current = index
                    type = 'current'
                }
                if (res.endBlock * 1 <= num * 1) {
                    type = 'past'
                }
                if (current === index) {
                    this.$store.commit('setCurrentPool', {
                        id: current,
                        type,
                        tokenCode: pools[current].TOKEN_CODE,
                        icon: poolLogos[pools[current].TOKEN_CODE],
                        pair: poolLogos[pools[current].REWARD_TICKER],
                        rewardPerBlock: res.rewardPerBlock,
                        startBlock: res.startBlock,
                        endBlock: res.endBlock
                    })
                }
                list.push({
                    id: index,
                    type,
                    // apr,
                    tokenCode: pools[index].TOKEN_CODE,
                    icon: poolLogos[pools[index].TOKEN_CODE]
                })
                index += 1
                if (index < len) {
                    this.getPoolInfo(id, pools, index, len, list, num, current)
                } else {
                    const length = len - 1
                    for (let i = length; i < 3; i ++) {
                        list.push({
                            type: 'default',
                            icon: poolLogos['WEN']
                        })
                    }
                    this.setPrice(pools, current, list)
                }
            }).catch(err => {
                console.log(err, 'getPoolInfo')
            })
        },
        setPrice(pools, index, currentData) {
            let chainName = ChainName[this.$store.state.chainId]
            // chainName = chainName.indexOf('HECO') !== -1 ? 'HECO' : 'BSC'
            // console.log(this.$web3.utils.keccak256('getReserves()').slice(0, 10), 999999999999, ADDRESS[chainName].pair)
            // this.$ajax('getMncReserves', {
            //     url: netWork[chainName].rpcUrls[0],
            //     method: 'post',
            //     data: {
            //         id: new Date().getTime(),
            //         method: 'eth_call',
            //         jsonrpc: '2.0',
            //         params: [{
            //             to: ADDRESS[chainName].pair,
            //             data: this.$web3.utils.keccak256('getReserves()').slice(0, 10)
            //         }, 'latest']
            //     }
            // }).then(res => {
            //     console.log(res, 7777777)
            // })
            const pastArray = []
            const currArray = []
            const nextArray = []
            const defArray = []
            currentData.forEach(item => {
                const type = item.type
                if (type === 'current') {
                    currArray.push(item)
                } else if (type === 'past') {
                    pastArray.push(item)
                } else if (type === 'next') {
                    nextArray.push(item)
                } else {
                    defArray.push(item)
                }
            })
            currentData = pastArray.concat(currArray, nextArray, defArray)
            if (process.env.VUE_APP_NODE_ENV !== 'production') {
                this.$store.commit('setMncPrice', 0.00075)
                this.$store.commit('setCurrentPoolPrice', 580)
                this.$store.commit('setPoolData', currentData)
                return
            }
            const PairContract = this.$vueweb3.contract(this.$abi.pair, pools[index].REWARD_TICKER)
            const pairContract = PairContract.methods
            const MncPairContract = this.$vueweb3.contract(this.$abi.pair, ADDRESS[chainName].pair)
            const mncPairContract = MncPairContract.methods
            mncPairContract.getReserves().call().then(res => {
                console.log(res, 11111)
                const mncUsdtAmount = this.$bigNumber(this.$web3.utils.fromWei(res._reserve0 + '', 'ether'))
                const mncPrice = mncUsdtAmount.div(this.$web3.utils.fromWei(res._reserve1 + '', 'ether')).toString()
                console.log(mncPrice, 'mncprice')
                pairContract.getReserves().call().then(res => {
                    let usdtNum = res._reserve1 + ''
                    let tokenNum = res._reserve0 + ''
                    if (chainName === 'HECO' || chainName === 'HECO_TESTNET') {
                        usdtNum = res._reserve0
                        tokenNum = res._reserve1
                    }
                    let usdtAmount = this.$bigNumber(this.$web3.utils.fromWei(usdtNum, 'ether'))
                    let price = usdtAmount.div(this.$web3.utils.fromWei(tokenNum, 'ether')).toString()
                    console.log(price, 'price')
                    this.$store.commit('setMncPrice', mncPrice)
                    this.$store.commit('setCurrentPoolPrice', price)
                    this.$store.commit('setPoolData', currentData)
                })
            })
        }
    },
    render (h) {
    if (this.$mobile) {
      return h('MLayout')
    } else {
      return h('PCLayout')
    }
  }
}
</script>

<style lang="less">
.main-loading {
    .el-loading-spinner {
        .el-icon-loading {
            color: #fff;
            font-size: 28px;
        }
        .el-loading-text {
            color: #fff;
        }
    }
}
</style>
