<template>
    <div class="home-content-02">
        <div class="title" v-if="$mobile"><img src="../../assets/images/mobile/content02.png" /> </div>
        <div class="title" v-else><img src="../../assets/images/pc/content02.png" /> </div>
        <div class="content">
            <div class="tips" v-html="$t('home.hotToWorks')">
            </div>
            <div class="list">
                <div class="item">
                    <div class="name"><span>{{$t('home.getNFT')}}</span></div>
                    <div class="info">
                        <div class="li">
                            <div class="warp">
                                <div><img src="../../assets/images/pc/1.png" /> </div>
                                <span>{{$t('home.buy')}}</span>
                                <div><img src="../../assets/images/pc/2.png" /> </div>
                            </div>
                        </div>
                        <div class="center">
                            <div class="line"></div>
                            <div class="text" v-html="$t('home.result')"></div>
                        </div>
                        <div class="li">
                            <div class="warp">
                                <div><img src="../../assets/images/pc/1.png" /> </div>
                                <span>+</span>
                                <div><img src="../../assets/images/pc/2.png" /> </div>
                            </div>
                        </div>
                        <div class="tip">
                            <span>{{$t('home.rebate')}}</span>
                            <span>{{$t('home.ownership')}}</span>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="name right"><span>{{$t('home.join')}}</span></div>
                    <div class="info right">
                        <div class="li">
                            <div class="warp">
                                <div><img src="../../assets/images/pc/2.png" /> </div>
                                <div><img src="../../assets/images/pc/4.png" /> </div>
                            </div>
                        </div>
                        <div class="center">
                            <div class="line right"></div>
                            <div class="text" v-html="$t('home.rewardsText')"></div>
                        </div>
                        <div class="li">
                            <div class="warp">
                                <div><img src="../../assets/images/pc/2.png" /> </div>
                                <div><img src="../../assets/images/pc/3.png" /> </div>
                            </div>
                        </div>
                        <div class="tip">
                            <span>{{$t('home.ownership')}}</span>
                            <span>{{$t('home.rewards')}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Content02',
    data() {
        return {
            list: []
        }
    }
}
</script>

<style lang="less">

</style>
