<template>
    <div class="tools-content-02">
        <div class="content">
            <div class="tools-stake-list">
                <div class="title" v-if="$mobile"><img src="../../assets/images/mobile/t-content02-1.png" /> </div>
                <div class="title" v-else><img src="../../assets/images/pc/t-content02-1.png" /> </div>
                <div class="left-button" v-if="$mobile && cpd_myStakeTools.length > 1" @click="onStakeLeft"><img src="../../assets/images/pc/left.png" /></div>
                <div class="right-button" v-if="$mobile && cpd_myStakeTools.length > 1" @click="onStakeRight"><img src="../../assets/images/pc/left.png" /></div>
                <div class="carousel-stake">
                    <div class="carousel-stake-content" :class="carouselStake" v-if="cpd_myStakeTools.length > 0" :style="{width: `${cpd_myStakeTools.length * 100}%`}">
                        <div class="info-item" v-for="(item, index) in cpd_myStakeTools" :key="index">
                            <div class="info-item-warp">
                                <div class="icon">
                                    <img :src="item.host + item.image_url" />
                                    <div class="star"><span>x{{item.stakeAmount}}</span></div>
                                </div>
                                <div class="name"><span>{{setName(item)}}</span><em>{{setName(item)}}</em></div>
                                <div class="count">
                                    <count v-model="item.backToCount" :max="item.stakeAmount*1" />
                                </div>
                                <div class="button">
                                    <el-button :loading="item.unstakeloading" :disabled="item.unstakeloading || item.stakeAmount == 0 || item.backToCount == 0" @click="unStake(item)">{{$t('tools.unStake')}}</el-button>
                                </div>
                                <div class="mining-rewards">
                                    <div class="rewards-title">
                                        <em></em><span>{{$t('tools.miningRewards')}}</span><em></em>
                                    </div>
                                    <div class="infos">
                                        <div class="mining-item">
                                            <label>{{$t('tools.mining')}}</label><span>{{$store.state.symbol}}</span>
                                        </div>
                                        <div class="mining-item">
                                            <label>{{$t('tools.apr')}}</label><span>{{setApr(item)}}%</span>
                                        </div>
                                        <div class="mining-item">
                                            <label>{{$t('tools.amount')}}</label><span>{{formatValue(formatReward(item.reward), 4)}} {{$store.state.symbol}}</span>
                                        </div>
                                    </div>
                                    <div class="mining-button">
                                        <el-button @click="redeemRewards(item)" :loading="item.rewardsloading" :disabled="item.rewardsloading">{{$t('tools.claim')}}</el-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="empty" v-else>
                        <template v-if="cpd_myTools.length > 0">
                            <div v-html="$t('tools.empty3')"></div>
                        </template>
                        <template v-else>
                           <div v-html="$t('tools.empty4')">
                           </div>
                            <el-button @click="$router.push('/')">{{$t('tools.buyNow')}}</el-button>
                        </template>
                    </div>
                </div>
            </div>
            <div class="tools-box-list">
                <div class="title" v-if="$mobile"><img src="../../assets/images/mobile/t-content02.png" /> </div>
                <div class="title" v-else><img src="../../assets/images/pc/t-content02.png" /> </div>
                <div class="left-button" v-if="$mobile && cpd_myTools.length > 1 " @click="onLeft"><img src="../../assets/images/pc/left.png" /></div>
                <div class="right-button" v-if="$mobile && cpd_myTools.length > 1 " @click="onRight"><img src="../../assets/images/pc/left.png" /></div>
                <div class="carousel">
                    <div class="carousel-content" :class="carousel" v-if="cpd_myTools.length > 0" :style="{width: `${cpd_myTools.length * 100}%`}">
                        <div class="info-item" v-for="(item, index) in cpd_myTools" :key="index">
                            <div class="info-item-warp">
                                <div class="icon">
                                    <img :src="item.host + item.image_url" />
                                    <div class="star"><span>{{item.myCount == 0 ? '' : `x${item.myCount}`}}</span></div>
                                </div>
                                <div class="name"><span>{{setName(item)}}</span><em>{{setName(item)}}</em></div>
                                <div class="level"><span v-for="(s, i) in item.level" :key="i"> * </span></div>
                                <div class="button">
                                    <el-button :disabled="item.stakeloading || item.myCount == 0 || item.stakeCount == 0" :loading="item.stakeloading" @click="stakeFunc(item)">{{$t('tools.stake')}}</el-button>
                                </div>
                                <div class="mining-rewards">
                                    <div class="rewards-title">
                                        <em></em><span>{{$t('tools.toolsInfo')}}</span><em></em>
                                    </div>
                                    <div class="infos">
                                        <div class="tools-item">
                                            <label>{{$t('tools.rarity')}}</label><span>{{item.totalCount}}</span>
                                        </div>
                                        <div class="tools-item">
                                            <label>{{$t('tools.price')}}</label><span>{{convert(item.staking)}}MNC</span>
                                        </div>
                                    </div>
                                    <div class="count">
                                        <count v-model="item.stakeCount" :max="item.myCount*1" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="empty" v-else>
                        <template v-if="cpd_myStakeTools.length > 0">
                            <div v-html="$t('tools.empty1')"></div>
                        </template>
                        <template v-else>
                            <div v-html="$t('tools.empty2')"></div>
                            <el-button @click="$router.push('/')">{{$t('tools.buyNow')}}</el-button>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
                :title="$t('home.stakeLater')"
                :visible.sync="dialogVisible"
                width="320px"
                :before-close="handleClose">
            <div class="staking-time-dialog">
                <div class="info">{{$t('tools.stakingTime')}}</div>
                <div class="button">
                    <el-button @click="scrollTop">{{$t('tools.stakingTimeBtn')}}</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {ADDRESS, ChainName} from '../../config/constant'
import toolsMixin from "../../mixins/toolsMixin"
import BigNumber from 'bignumber.js'
import moment from 'moment'

BigNumber.config({ EXPONENTIAL_AT: 1e+9 })

export default {
    name: 'ContentTools02',
    mixins: [toolsMixin],
    data() {
        return {
            carousel: 'carousel-01',
            carouselStake: 'carousel-stake-01',
            count: 1,
            stakeCount: 1,
            dialogVisible: false,
            timeout: null
        }
    },
    computed: {
        cpd_myTools() {
            const list = this.tools.filter(item =>  item.myCount != '0')
            return list
        },
        cpd_myStakeTools() {
            const list = this.tools.filter(item =>  item.stakeAmount != '0' || item.reward * 1 > 0)
            return list
        }
    },
    watch: {
      // '$store.state.chainId'() {
      //     if (!this.$store.state.checkChainId) {
      //         return
      //     }
      //     this.init()
      // },
        '$store.state.poolData'() {
            if (!this.$store.state.checkChainId) {
                return
            }
            this.init()
        }
    },
    mounted() {
        if (this.$store.state.chainId && this.$store.state.checkChainId && this.$store.state.poolData.length > 0) {
            this.init()
        }
    },
    methods: {
        init() {
            this.initTools().then(() => {
                this.tools.forEach(item => {
                    this.loadData(item)
                })
                this.getAmount()
            })
        },
        loadData(item) {
            const id = item.id
            // console.log(ChainName[this.$store.state.chainId], 'ChainName[this.$store.state.chainId]')
            const minersNFTs = this.$vueweb3.contract(this.$abi.MinersNFTs, ADDRESS[ChainName[this.$store.state.chainId]].minersnfts).methods
            minersNFTs.balanceOf(this.$store.state.account, id).call({}, (error, result) => {
                if (result) {
                    result = this.$web3.utils.fromWei(result + '', 'ether')
                    const staking = item.staking || 0
                    const myCount = result
                    const bn = this.$bigNumber(staking)
                    const total = bn.times(result).toString()
                    this.$store.commit('setLoading', false)
                    this.setTools(id,{
                        myCount,
                        total
                    })
                }
            })
            const stakingPools = this.$vueweb3.contract(this.$abi.StakingPools, ADDRESS[ChainName[this.$store.state.chainId]].stakingpools(this.$store.state.currentPool.id)).methods
            stakingPools.userData(id, this.$store.state.account).call().then(result => {
                // console.log(result, 'result---')
                const stakeAmount = this.$web3.utils.fromWei(result.stakeAmount, 'ether')
                const stakeStatus = item.myCount > 0 ? 1 : 0
                this.$store.commit('setLoading', false)
                this.setTools(id,{
                    stakeAmount,
                    stakeStatus
                })
            })
            stakingPools.poolInfos(id).call({from: this.$store.state.account}).then(result => {
                this.$web3.eth.getBlock("latest").then(lastBlock => {
                    const endTime = (result.endBlock - lastBlock.number) * 3 + lastBlock.timestamp
                    const days = moment(endTime* 1000).diff(moment(), 'days')
                    const hours = moment.duration(moment(endTime* 1000).diff(moment())).hours()
                    const surplusDays = `${days} days ${hours} hrs`
                    this.$store.commit('setLoading', false)
                    this.setTools(id,{
                        endTime: surplusDays
                    })
                })
            })
        },
        setTools(id, obj) {
            const tools = this.tools.map(item =>{
                if (item.id === id) {
                    item = Object.assign(item, obj)
                }
                return item
            })
            this.tools = tools
        },
        onLeft() {
            if (this.count <= 1) {
                return
            }
            this.count -= 1
            this.carousel = 'carousel-0' + this.count
        },
        onRight() {
            if (this.count >= this.cpd_myTools.length) {
                return
            }
            this.count += 1
            this.carousel = 'carousel-0' + this.count
        },
        onStakeLeft() {
            if (this.stakeCount <= 1) {
                return
            }
            this.stakeCount -= 1
            this.carouselStake = 'carousel-stake-0' + this.stakeCount
        },
        onStakeRight() {
            if (this.stakeCount >= this.cpd_myStakeTools.length) {
                return
            }
            this.stakeCount += 1
            this.carouselStake = 'carousel-stake-0' + this.stakeCount
        },
        stakeFunc(item) {
            if (!item.stakingNow) {
                this.dialogVisible = true
            } else {
                this.stake(item)
            }
        },
        stake(item) {
            const id = item.id
            item.stakeloading = true
            const amount = item.stakeCount + ''
            const stakingPools = this.$vueweb3.contract(this.$abi.StakingPools, ADDRESS[ChainName[this.$store.state.chainId]].stakingpools(this.$store.state.currentPool.id)).methods
            const nft = this.$vueweb3.contract(this.$abi.MinersNFTs, ADDRESS[ChainName[this.$store.state.chainId]].minersnfts).methods
            this.checkingApproveNFt(nft, amount, this.$store.state.account).then(res => {
                if (!res) {
                    nft.setApprovalForAll(ADDRESS[ChainName[this.$store.state.chainId]].stakingpools(this.$store.state.currentPool.id), this.$web3.utils.toWei(amount, 'ether')).send({from: this.account}).then(() => {
                        stakingPools.stake(id, this.$web3.utils.toWei(amount, 'ether')).send({
                            from: this.account,
                            gas: '2100000'
                        }).then(result => {
                            this.getTransactionReceipt(result.transactionHash, () => {
                                item.stakeloading = false
                                this.loadData(item)
                            })
                        }).catch(() => {
                            this.$message.error('transaction failed')
                            // console.log(err.receipt)
                            item.stakeloading = false
                        })
                    })
                } else {
                    stakingPools.stake(id, this.$web3.utils.toWei(amount, 'ether')).send({
                        from: this.account,
                        gas: '210000'
                    }).then(result => {
                        this.getTransactionReceipt(result.transactionHash, () => {
                            item.stakeloading = false
                            this.loadData(item)
                        })
                    }).catch(() => {
                        this.$message.error('transaction failed')
                        // console.log(err.receipt)
                        item.stakeloading = false
                    })
                }
            }).catch(err => {
                item.stakeloading = false
                this.loadData(item)
            })
        },
        unStake(item) {
            const id = item.id
            const amount = item.backToCount + ''
            item.unstakeloading = true
            const stakingPools = this.$vueweb3.contract(this.$abi.StakingPools, ADDRESS[ChainName[this.$store.state.chainId]].stakingpools(this.$store.state.currentPool.id)).methods
            stakingPools.unstake(id, this.$web3.utils.toWei(amount, 'ether')).send({from: this.account, gas: '210000'}).then(result => {
                this.getTransactionReceipt(result.transactionHash, () => {
                    item.unstakeloading = false
                    this.loadData(item)
                })
            }).catch(err => {
                this.$message.error('transaction failed')
                console.log(err)
                item.unstakeloading = false
            })
        },
        userData(item) {
            const id = item.id
            const StakingPools = this.$vueweb3.contract(this.$abi.StakingPools, ADDRESS[ChainName[this.$store.state.chainId]].stakingpools(this.$store.state.currentPool.id)).methods
            return StakingPools.getReward(id, this.$store.state.account).call().then(result => {
                // console.log(result, 'getReward', id, ADDRESS[ChainName[this.$store.state.chainId]], this.$store.state.currentPool.id)
                item.reward = result //this.$web3.utils.fromWei(result, 'ether')
                return item
            })
        },
        getAmount() {
            if (this.timeout) {
                clearTimeout(this.timeout)
            }
            const promises = []
            this.tools.forEach(item => {
                promises.push(this.userData(item))
            })
            Promise.all(promises).then(data => {
                const amountArr = []
                const tools = []
                data.forEach(item => {
                    tools.push(item)
                    amountArr.push(this.$web3.utils.fromWei(item.reward))
                })
                let amount = BigNumber.sum(...amountArr).toString()
                // amount = this.$web3.utils.fromWei(amount, 'ether')
                // console.log(amount, 'amount')
                this.$store.commit('setRewardAmount', amount)
                this.$store.commit('setTools', tools)
                this.timeout = setTimeout(() => {
                    this.getAmount()
                }, 1000 * 20)
            })
        },
        formatReward(v) {
            return this.$web3.utils.fromWei(v + '', 'ether')
        },
        redeemRewards(item, hideLoadiing) {
            item.rewardsloading = !hideLoadiing
            // const rewardToken = this.$vueweb3.contract(this.$abi.ERC20, this.$store.state.rewardTokenAddress).methods
            // const amount = this.$web3.utils.toWei(item.reward, 'ether')
            const stakingPools = this.$vueweb3.contract(this.$abi.StakingPools, ADDRESS[ChainName[this.$store.state.chainId]].stakingpools(this.$store.state.currentPool.id)).methods
            stakingPools.redeemRewards(item.id).send({from: this.$store.state.account}).then(result => {
                item.rewardsloading = false
                if (result.status) {
                    this.getAmount()
                }
            }).catch((err) => {
                console.log(err)
                item.rewardsloading = false
            })
        },
        handleClose() {
            this.dialogVisible = false
        },
        scrollTop() {
            document.body.scrollTop = document.documentElement.scrollTop = 80
            this.dialogVisible = false
        }
    }
}
</script>

<style lang="less">

</style>
