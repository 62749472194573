<template>
    <div class="page-tools">
        <div class="tools-content">
            <content01 />
            <content02 />
            <content03 />
            <div class="tools-content-bg"></div>
        </div>
    </div>
</template>

<script>
import content01 from '../../../components/tools/content-01'
import content02 from '../../../components/tools/content-02'
import content03 from '../../../components/tools/content-03'
export default {
    name: 'Home',
    components: {
        content01,
        content02,
        content03
    }
}
</script>

<style lang="less">
.page-tools {
    display: flex;
    max-width: 1920px;
    margin: 0 auto;
    flex-direction: column;
    .tools-content {
        width: 1114px;
        height: 3894px;
        background-image: url("../../../assets/images/pc/tools-bg-01.png");
        background-repeat: no-repeat;
        margin: 36px auto 0 auto;
        position: relative;
        .tools-content-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../../../assets/images/pc/tools-bg-02.png");
            background-repeat: no-repeat;
            pointer-events: none;
            z-index: 11;
        }
        .tools-content-01 {
            display: flex;
            flex-direction: column;
            position: relative;
            height: 830px;
            width: 100%;
            overflow: hidden;
            .title {
                display: flex;
                justify-content: center;
                position: absolute;
                width: 100%;
                top: 136px;
            }
            .train {
                position: absolute;
                bottom: 0;
                z-index: 8;
            }
            .content-01 {
                height: 814px;
                width: 100%;
                overflow: hidden;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                z-index: 1;
            }
            .content {
                position: relative;
                .token-info {
                    border: solid 3px #fff;
                    background-color: #E8B12A;
                    padding: 24px 24px 12px 24px;
                    border-radius: 13px;
                    margin: 0 auto;
                    color: #fff;
                    font-weight: bold;
                    position: relative;
                    min-width: 300px;
                    text-align: center;
                    .icon {
                        position: absolute;
                        background-color: #B59E47;
                        /*border: solid 2px #fff;*/
                        width: 80px;
                        height: 80px;
                        border-radius: 50%;
                        top: -40px;
                        left: 20px;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .tips {
                        font-size: 22px;
                        text-align: center;
                        padding-top: 12px;
                        padding-bottom: 4px;
                    }
                    .amount {
                        font-size: 32px;
                        color: #000;
                        background-color: #fff;
                        border-radius: 13px;
                        padding: 6px 16px;
                        text-align: center;
                    }
                    h2 {
                        text-align: center;
                        font-size: 18px;
                        font-family: ChalkboardBold;
                    }
                    .history-button {
                        padding: 12px 0 16px 0;
                        display: flex;
                        justify-content: center;
                        span {
                            height: 28px;
                            line-height: 28px;
                            font-size: 14px;
                            /*color: rgba(255, 255, 255, 0.5);*/
                            /*text-decoration-line: underline;*/
                            /*text-decoration-color: rgba(255, 255, 255, 0.5);*/
                            color: #fff;
                            border: solid 1px #fff;
                            padding: 0 24px;
                            border-radius: 9px;
                        }
                    }
                }
                .count-down {
                    background-color: #000;
                    margin: 0 auto;
                    padding: 0 8px;
                    color: #fff;
                    width: 80%;
                    .tip {
                        line-height: 32px;
                        text-align: center;
                        font-size: 18px;
                    }
                    .time {
                        background-color: #232323;
                        height: 56px;
                        display: flex;
                    }
                    .time-tip {
                        display: flex;
                        span {
                            flex: 1;
                            text-align: center;
                            line-height: 24px;
                        }
                    }
                }
                .line-02 {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    min-height: 12px;
                    span {
                        width: 22px;
                        background-color: #898989;
                        border-style: solid;
                        border-color: #000;
                        border-width: 0 3px;
                        &:first-of-type {
                            margin-left: 82px;
                        }
                        &:last-of-type {
                            margin-right: 82px;
                        }
                    }
                }
            }
        }
        .tools-content-02 {
            display: flex;
            flex-direction: column;
            position: relative;
            height: 1816px;
            width: 100%;
            overflow: hidden;
            .title {
                display: flex;
                justify-content: center;
                position: absolute;
                width: 100%;
                top: 122px;
            }
            .content {
                display: flex;
                justify-content: center;
                width: 100%;
                flex-direction: column;
                color: #fff;
                .left-button {
                    position: absolute;
                    left: 118px;
                    top: 116px;
                    cursor: pointer;
                    opacity: 0.6;
                    &:hover {
                        opacity: 1;
                    }
                }
                .right-button {
                    position: absolute;
                    right: 118px;
                    top: 116px;
                    transform: rotate(180deg);
                    cursor: pointer;
                    opacity: 0.6;
                    &:hover {
                        opacity: 1;
                    }
                }
                .carousel {
                    width: 100%;
                    overflow: hidden;
                    margin: 0 auto;
                    padding: 142px 0 24px 0;
                }
                .carousel-content {
                    width: 100% !important;
                    display: flex;
                    justify-content: center;
                    transform: translateX(0);
                    transition: transform 0.6s;
                    &.carousel-01 {
                        transform: translateX(0);
                    }
                    &.carousel-02 {
                        transform: translateX(-33%);
                    }
                    &.carousel-03 {
                        transform: translateX(-67%);
                    }
                }
                .item {
                    border: solid 3px #fff;
                    border-radius: 15px;
                    padding: 24px;
                    flex: 1;
                    margin: 0 12px;
                    font-size: 18px;
                    position: relative;
                    background-color: rgba(229, 148, 41, 0.6);
                    display: flex;
                    flex-direction: column;
                    /*overflow: hidden;*/
                    .name {
                        height: 24px;
                        line-height: 24px;
                        background-color: #e19a2c;
                        position: absolute;
                        padding: 0 24px;
                        display: flex;
                        left: 24px;
                        top: 24px;
                        span {
                            width: 100%;
                            text-align: center;
                            font-size: 20px;
                            font-family: ChalkboardSEBold;
                            word-break: break-word;
                            white-space: nowrap;
                        }
                        em {
                            word-break: break-word;
                            white-space: nowrap;
                            font-family: ChalkboardSEBold;
                            font-style: normal;
                            padding-left: 12px;
                        }
                    }
                    .info {
                        display: flex;
                        padding-top: 24px;
                        .left {
                            display: flex;
                            flex-direction: column;
                            padding-right: 78px;
                            .icon {
                                width: 188px;
                                height: 188px;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .star {
                                display: flex;
                                justify-content: center;
                                font-size: 40px;
                                font-family: ChalkboardBold;
                            }
                        }
                        .right {
                            position: relative;
                            .detail {
                                padding-top: 24px;
                                .li {
                                    display: flex;
                                    padding-bottom: 16px;
                                    label {
                                        width: 220px;
                                        color: rgba(255, 255, 255, 0.4);
                                    }
                                }
                            }
                            .buttons {
                                display: flex;
                                /*justify-content: space-between;*/
                                position: absolute;
                                bottom: 24px;
                                height: 36px;
                                width: 100%;
                                box-sizing: border-box;
                                button {
                                    box-sizing: border-box;
                                    font-size: 16px;
                                    flex: 1;
                                    border-radius: 5px;
                                    height: 38px;
                                    margin-left: 24px;
                                }
                            }
                        }
                    }
                    p {
                        padding-bottom: 24px;
                    }
                }
                .carousel-stake {
                    width: 100%;
                }
                .carousel-stake-content {
                    display: flex;
                    width: 100% !important;
                    justify-content: center;
                }
                .mining-rewards {
                    background-color: rgba(253, 203, 91, 0.3);
                    margin-top: 36px;
                    position: relative;
                    padding: 24px;
                    .rewards-title {
                        display: flex;
                        align-items: center;
                        height: 24px;
                        position: absolute;
                        left: 0;
                        width: 100%;
                        top: -13px;
                        * {
                            flex: 1;
                        }
                        em {
                            height: 3px;
                            background-color: #fff;
                        }
                        span {
                            white-space: nowrap;
                            padding: 0 12px;
                            line-height: 24px;
                        }
                    }
                    .tools-item {
                        display: flex;
                        flex-direction: column;
                        padding-bottom: 16px;
                        label {
                            color: rgba(255, 255, 255, 0.4);
                            font-family: Chalkboard;
                            span {
                                font-size: 14px;
                            }
                        }
                        span {
                            font-size: 20px;
                        }
                    }
                    .mining-item {
                        display: flex;
                        justify-content: space-between;
                        padding-bottom: 16px;
                    }
                    .mining-button {
                        padding: 0 36px;
                        display: flex;
                        button {
                            background-color: #2C99F1;
                            height: 42px;
                            border-radius: 5px;
                            color: #fff;
                            font-family: ChalkboardSEBold;
                            font-size: 18px;
                            cursor: pointer;
                            flex: 1;
                        }
                    }
                }
                .empty {
                    text-align: center;
                    font-size: 24px;
                    min-height: 400px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    button {
                        margin-top: 12px;
                    }
                }
                .tools-box-list {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    position: relative;
                    margin: 0 auto;
                    width: 76%;
                    padding-top: 100px;
                    height: 800px;
                    .title {
                        top: 60px;
                    }
                    .name {
                        position: relative!important;
                        margin-top: 100px!important;
                    }
                    .icon {
                        position: absolute!important;
                        top: -99px;
                        left: 50%;
                        margin-left: -99px!important;
                        .star {
                            top: 0!important;
                            right: -10px!important;
                        }
                    }
                    .level {
                        font-size: 32px;
                    }
                    .button {
                        position: absolute;
                        bottom: -20px;
                        z-index: 1111;
                    }
                }
                .tools-stake-list {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    position: relative;
                    margin: 120px auto 0 auto;
                    width: 84%;
                    padding-top: 120px;
                    height: 800px;
                    .title {
                        top: 0;
                    }
                }
                .info-item {
                    border: solid 3px #fff;
                    border-radius: 15px;
                    position: relative;
                    padding: 24px 0 0 0;
                    margin: 0 12px;
                    text-align: center;
                    font-size: 16px;
                    max-width: 293px;
                    flex: 1;
                    color: #fff;
                    font-family: ChalkboardBold;
                    background-color: rgba(229, 148, 41, 0.6);
                    .icon {
                        width: 188px;
                        height: 188px;
                        margin: 0 auto;
                        position: relative;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                        .star {
                            display: flex;
                            justify-content: center;
                            font-size: 22px;
                            font-family: ChalkboardBold;
                            padding: 24px 0;
                            position: absolute;
                            top: -10px;
                            right: -10px;
                        }
                    }
                    .name {
                        font-size: 22px;
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        padding: 0 24px;
                        box-sizing: border-box;
                        display: flex;
                        justify-content: center;
                        span {
                            position: absolute;
                            top: -16px;
                            left: 0;
                            width: 100%;
                            text-align: center;
                            z-index: 2;
                        }
                        em {
                            color: transparent;
                            position: relative;
                            top: -3px;
                            border-top: solid 3px #e19a2c;
                            z-index: 0;
                            padding: 0 16px;
                        }
                    }
                    .count {
                        margin: 24px auto;
                        width: 70%;
                    }
                    .button {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        padding: 12px 60px 0 60px;
                        box-sizing: border-box;
                        button {
                            background-color: #2C99F1;
                            height: 42px;
                            border-radius: 5px;
                            color: #fff;
                            font-family: ChalkboardSEBold;
                            font-size: 18px;
                            cursor: pointer;
                            flex: 1;
                        }
                    }
                }
            }
        }
        .tools-content-03 {
            display: flex;
            flex-direction: column;
            position: relative;
            height: 1160px;
            width: 100%;
            overflow: hidden;
            .title {
                display: flex;
                justify-content: center;
                position: absolute;
                width: 100%;
                top: 170px;
            }
            .content {
                display: flex;
                flex-direction: column;
                width: 65%;
                margin: 270px auto 0 auto;
                color: #fff;
                .empty {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 22rem;
                    text-align: center;
                }
                .rebate-content {
                    width: 100%;
                    max-height: 644px;
                    overflow: auto;
                }
                .item {
                    border-bottom: solid 1px #fff;
                    padding: 12px 0;
                    font-size: 18px;
                    position: relative;
                    text-align: center;
                    display: flex;
                    &.disabled {
                        color: rgba(255, 255, 255, 0.2);
                    }
                    > * {
                        flex: 1;
                    }
                    span {
                        font-size: 14px;
                        color: #fff;
                    }
                    div {
                        font-family: ChalkboardSEBold;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    button {
                        background-color: #2C99F1;
                        padding: 0 24px;
                        height: 28px;
                        line-height: 24px;
                        border-radius: 9px;
                        color: #fff;
                        font-size: 14px;
                        font-family: ChalkboardSEBold;
                        cursor: pointer;
                        span {
                            color: #fff;
                        }
                    }
                }
                .buttons {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .tips {
                        font-size: 18px;
                        text-align: center;
                        padding: 24px 0 12px 0;
                    }
                    button {
                        width: 30%;
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}
</style>
