import Vue from "vue"

Vue.component('coundown', resolve => {
    import('./common/coundown').then(comp => {
        resolve(comp)
    })
})

Vue.component('count', resolve => {
    import('./common/count').then(comp => {
        resolve(comp)
    })
})

Vue.component('checkBox', resolve => {
    import('./common/checkbox').then(comp => {
        resolve(comp)
    })
})

Vue.component('cSelect', resolve => {
    import('./common/select').then(comp => {
        resolve(comp)
    })
})

Vue.component('newMenu', resolve => {
    import('./header/menu').then(comp => {
        resolve(comp)
    })
})
