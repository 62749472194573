export default {
    menu: {
        home: '首页',
        marktplace: '交易市场',
        myTools: '我的工具',
        connect: '连接钱包'
    },
    home: {
        mining: '{0} 掘矿',
        apr: '赚取最高{0}%年化收益率',
        countStart: '掘矿周期即将开始',
        countEnd: '掘矿周期结束倒数',
        tipInfo: '购买NFT工具和质押NFT工具来挖掘MNC代币',
        preparingTime: '购买时间',
        preparingTimeDate: '2021年7月19日 15:00 - 2021年8月18日',
        preparingTimeDate1: '2021年8月18日 15:00',
        campaignPeriod: '掘矿时间',
        campaignPeriodDate: '2021年7月23日 00:00 - 2021年8月21日 00:00',
        campaignPeriodDate1: '2021年8月22日 15:00 - 2021年9月20日 15:00',
        remainRarity: '剩余/稀有度',
        price: '价格<br /><span>(60天后100%返还)</span>',
        after: '(60天后100%返还)',
        rebateDate: 'MNC 可于60天后取回',
        buy: '购买',
        soldOut: '售罄',
        days: '天',
        youBuy: '你要购买',
        buyed: '恭喜你!<br />购买了 {0} 个 ',
        stakeContent: '在活动期间随时质押您的 NFT 工具，每天都会收到掘矿奖励喔！',
        aprText: '年化收益率高達',
        approve: '授权',
        confirm: '确认',
        stakeNow: '立即质押',
        hotToWorks: '<p>Metaminer 是一个建立在区块链技术之上的虚拟世界，用户可以通过收集挖掘工具来增值链上资产。</p><p>Metaminers 世界中的所有 NFT 工具都有能力挖掘市场上不同的热门代币。</p>',
        getNFT: '获取 NFT',
        result: '60天后...<br />(获得)',
        rebate: 'MNC 全数返还',
        join: '参加活动',
        ownership: 'NFT 拥有权',
        rewardsText: '掘矿奖励基于<br />掘矿日数和工具种类...<br />(获得)',
        rewards: '奖励',
        current: '当前活动',
        content3: '<p>高达{0}%的年化</p><p>{1}</p>',
        comingSoon: '即将推出',
        yapr: '<p>下个活动</p><p>Bitcoin Cash</p>',
        stakeLater: '稍后质押',
        stakeNowApprove: '质押授权',
        balanceText: '可用MNC',
        completed: '已完成'
    },
    tools: {
        earning: '收益',
        miningRewards: '掘矿奖励',
        mining: '掘矿',
        apr: '年化',
        amount: '数量',
        claim: '领取',
        toolsInfo: '工具详情',
        rarity: '稀有度',
        price: '价值 (可返还)',
        empty: '暂无数据',
        empty1: '<p>你的工具箱没有工具,</p><p>所有的工具都已用来掘矿!</p>',
        empty2: '<p>你的工具箱没有工具,</p><p>购买NFT工具开始挖矿!</p>',
        buyNow: '立即购买',
        empty3: '<p>质押你的NFT工具</p><p>开始挖矿吧!</p>',
        empty4: '<p>购买NFT工具</p><p>开始挖矿吧!</p>',
        purchaseBlock: '区块',
        toolType: '类型',
        beRebated: '可返还 MNC',
        stake: '质押',
        unStake: '解押',
        rebatableAmount: '返还',
        rebate: '提取',
        rebateText: '尚沒有返还，购买NFT工具，60天后就可以返还喔！',
        stakingTime: '质押还没开始，请稍后回来',
        stakingTimeBtn: '查看开始时间',
        history: '历史活动'
    },
    common: {
        'Whac-A-Mole': '地鼠掘矿锤',
        'Mjolnir': '雷神掘矿锤',
        'Rocket Drill': '火箭掘矿炮',
        back: '返回'
    },
    bridge: {
        bridge: '跨链桥',
        menu: {
            home: '首页',
            marktplace: '交易市场',
            myTools: '我的工具',
            connect: '连接钱包'
        },
        balance: '余额',
        max: '最大',
        from: '从',
        to: '到',
        transfer: '转移',
        withdraw: '提取',
        amount: '数量',
        progress: '进度',
        checkingText: '正在检查 {1} 上的 {0} 数量',
        next: '下一步',
        transferAmount: '跨链数量',
        transactionFee: '手续费',
        confirm: '确认',
        approve: '批准',
        loading: '加载中',
        transferCompleted: '转移完成',
        transferringText: '正在将 {0} MNC 从 <b>{1}</b> 转移至 <b>{2}</b>',
        pleaseWait: '这个过程可能需要几分钟, 请耐心等待',
        transferedText: '已将 {0} MNC 从 {1} 转移至 {2}',
        dialogTitle: {
            1: '正在准备您的转移请求',
            2: '等待链上确认',
            3: '您的资产正在转移中',
            4: '正在完成您的转移'
        }
    }
}
