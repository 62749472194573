<template>
    <div class="tools-content-03">
        <div class="title" v-if="$mobile"><img src="../../assets/images/mobile/t-content03.png" /> </div>
        <div class="title" v-else><img src="../../assets/images/pc/t-content03.png" /> </div>
        <div class="content" v-if="list.length > 0">
            <div class="item th">
                <span style="flex: 2">{{$t('tools.purchaseBlock')}}</span>
                <span style="flex: 1">{{$t('tools.toolType')}}</span>
                <span style="flex: 1">{{$t('tools.amount')}}</span>
                <span style="flex: 2">{{$t('tools.beRebated')}}</span>
            </div>
            <div class="rebate-content">
                <div class="item" v-for="(item, index) in list" :key="index" :class="{disabled: item.disabled}">
                    <div style="flex: 2">{{item.unlockBlock}}</div>
                    <div style="flex: 1">{{setName(item)}}</div>
                    <div style="flex: 1">{{item.amount}}</div>
                    <div style="flex: 2">{{convert(item.price)}}</div>
                </div>
            </div>
            <div class="buttons">
                <div class="tips">{{$t('tools.rebatableAmount')}}: {{this.amount}} MNC</div>
                <el-button :loading="false" :disabled="!cpd_unDisabled" @click="rebateble()">{{$t('tools.rebate')}}</el-button>
            </div>
        </div>
        <div class="content" v-else>
            <div class="empty" v-html="$t('tools.rebateText')"></div>
        </div>
    </div>
</template>

<script>
    import {ADDRESS, ChainName} from "../../config/constant";
import toolsMixin from "../../mixins/toolsMixin";
import BigNumber from 'bignumber.js'

BigNumber.config({ EXPONENTIAL_AT: 1e+9 })

export default {
    name: 'Content03',
    mixins: [toolsMixin],
    data() {
        return {
            check: '',
            list: [],
            amount: 0
        }
    },
    computed: {
      cpd_unDisabled() {
          let check = false
          this.list.forEach(item => {
              if (!item.disabled) {
                  check = true
              }
          })
          return check
      }
    },
    watch: {
      '$store.state.tools'() {
          this.setValue()
      },
        // '$store.state.chainId'() {
        //     if (!this.$store.state.checkChainId) {
        //         return
        //     }
        //     this.getData()
        // },
        '$store.state.poolData'() {
            if (!this.$store.state.checkChainId) {
                return
            }
            this.getData()
        }
    },
    mounted() {
        if (this.$store.state.chainId && this.$store.state.checkChainId && this.$store.state.poolData.length > 0) {
            this.getData()
        }
    },
    methods: {
        getData() {
            // console.log('getData')
            this.list = []
            const mncNFTStore = this.$vueweb3.contract(this.$abi.MncNFTStore, ADDRESS[ChainName[this.$store.state.chainId]].mncnftstore).methods
            mncNFTStore.userVestingInfo(this.$store.state.account).call().then(result => {
                // console.log(result, 'userVestingInfo')
                for (let i = 1; i <= result.lastVesting; i++) {
                    mncNFTStore.userVesting(this.$store.state.account, i).call().then(res => {
                        // console.log(res, 'userVesting')
                        const price = this.$web3.utils.fromWei(res.amount)
                        this.$web3.eth.getBlock("latest").then(lastBlock => {
                            const disabled = !!(lastBlock.number < res.unlockBlock)
                            if (price != 0) {
                                this.list.push({
                                    id: res.productId,
                                    name: '',
                                    locale: {},
                                    unlockBlock: res.unlockBlock,
                                    amount: '',
                                    price,
                                    disabled
                                })
                            }
                        })
                    })
                }
            })
        },
        setValue() {
            const tools = this.$store.state.tools
            const toolsData = {}
            tools.forEach(item => {
                toolsData[item.id] = item
            })
            const prices = []
            this.list.forEach((item, index) => {
                item.name = toolsData[item.id].name
                item.locale = toolsData[item.id].locale
                item.amount = this.$bigNumber(item.price).div(toolsData[item.id].staking).toString()
                if (!item.disabled) {
                    prices.push(item.price)
                }
                this.$set(this.list, index, item)
            })
            this.amount = prices.length === 0 ? 0 : BigNumber.sum(...prices).toString()
        },
        rebateble() {
            const mncNFTStore = this.$vueweb3.contract(this.$abi.MncNFTStore, ADDRESS[ChainName[this.$store.state.chainId]].mncnftstore).methods
            mncNFTStore.rebatable(this.$store.state.account).call().then(res => {
                console.log(res)
                mncNFTStore.rebate(res).send({from: this.$store.state.account}).then(result => {
                    // console.log(result, 11111111)
                    this.getData()
                })
            })
        }
    }
}
</script>

<style lang="less">

</style>
