import BigNumber from "bignumber.js";
import {ChainName, netWork} from "../config/constant";
export default {
    methods: {
        decimalValue(val, decimal) {
            const value = this.$bigNumber(val)
            return  value.toFixed(decimal) * 1
        },
        checkChainId(chainId) {
            const check = !!netWork[ChainName[chainId]]
            this.$store.commit('setCheckChainId', check)
            return check
        }
    }
}
