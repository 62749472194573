<template>
    <div class="page-bridge-home-pc">
        <div class="banner">
            <div class="bg1" />
            <div class="bg2" />
            <div class="train" />
            <div class="liuxing-01"></div>
            <div class="liuxing-02"></div>
            <div class="liuxing-03"></div>
            <banner />
            <div class="text" v-if="$i18n.locale === 'en-US'">
                <img src="../../../assets/images/bridge/pc/banner-text.png" />
            </div>
            <div class="text" v-else style="margin-left: -258px; top: 202px">
                <img src="../../../assets/images/bridge/pc/banner-text-zh.png" />
            </div>
        </div>
        <div class="home-content">
            <page-content />
        </div>
    </div>
</template>

<script>
import banner from '../../../components/bridge/banner'
import pageContent from '../../../components/bridge/content'
export default {
    name: 'Home',
    components: {
        banner,
        pageContent
    },
    methods: {
    }
}
</script>

<style lang="less">
.page-bridge-home-pc {
    display: flex;
    max-width: 1920px;
    margin: 0 auto;
    flex-direction: column;
    min-height: 656px;
    background-image: url("../../../assets/images/bridge/pc/banner.png");
    background-repeat: no-repeat;
    background-position: center top;
    .banner {
        height: 656px;
        width: 100%;
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        z-index: 1;
        .text {
            position: absolute;
            top: 170px;
            left: 50%;
            margin-left: -334px;
        }
        .content {
            position: relative;
            .token-info {
                border: solid 3px #000;
                /*background-color: #E8B12A;*/
                background-color: #F2C96E;
                padding: 12px 24px;
                border-radius: 11px;
                margin: 0 auto;
                color: #000;
                font-size: 24px;
                font-weight: bold;
                h2 {
                    text-align: center;
                    font-size: 32px;
                    font-family: ChalkboardBold;
                }
            }
            .line-01 {
                width: 100%;
                display: flex;
                justify-content: space-between;
                min-height: 24px;
                &:first-of-type {
                    height: 56px;
                }
                &:last-of-type {
                    span {
                        border-width: 0 3px 3px 3px;
                        border-radius: 0 0 50% 50%;
                    }
                }
                span {
                    width: 22px;
                    background-color: #898989;
                    border-style: solid;
                    border-color: #000;
                    border-width: 0 3px;
                    &:first-of-type {
                        margin-left: 82px;
                    }
                    &:last-of-type {
                        margin-right: 82px;
                    }
                }
            }
        }
        .bg1 {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            background-image: url("../../../assets/images/bridge/pc/bg1.png");
            background-repeat: no-repeat;
            background-position: bottom center;
        }
        .bg2 {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;
            background-image: url("../../../assets/images/bridge/pc/bg2.png");
            background-repeat: no-repeat;
            background-position: bottom center;
        }
        .train {
            width: 3542px;
            height: 121px;
            background-image: url("../../../assets/images/bridge/pc/train.png");
            background-repeat:  repeat-x;
            background-position: center right;
            position: absolute;
            top: 418px;
            z-index: 0;
            transform: translateX(-3544px);
            animation: train 18s linear infinite;
        }
        .liuxing-01 {
            width: 215px;
            height: 185px;
            background: url("../../../assets/images/bridge/pc/liuxing-01.png") no-repeat;
            position: absolute;
            top: -185px;
            left: -215px;
            transform: translate(0, 0);
            animation: liuxing01 5s ease-in infinite;
            z-index: 0;
        }
        .liuxing-02 {
            width: 308px;
            height: 277px;
            background: url("../../../assets/images/bridge/pc/liuxing-02.png") no-repeat;
            position: absolute;
            top: -277px;
            left: 0;
            transform: translate(0, 0);
            animation: liuxing02 6s ease-in infinite 3s;
            z-index: 0;
        }
        .liuxing-03 {
            width: 282px;
            height: 235px;
            background: url("../../../assets/images/bridge/pc/liuxing-03.png") no-repeat;
            position: absolute;
            top: -235px;
            left: 200px;
            transform: translate(0px, -235px);
            animation: liuxing03 4s infinite 6s;
            z-index: 0;
        }
    }
    .home-content {
        width: 800px;
        margin: 0 auto;
        padding: 64px 24px 36px 24px;
        box-sizing: border-box;
        .page-content {
            width: 100%;
            background-color: #F7B746;
            border-radius: 19px;
            padding: 94px 24px 36px 24px;
            position: relative;
            box-sizing: border-box;
            color: #fff;
            .title {
                position: absolute;
                left: 0;
                top: -36px;
                width: 100%;
                display: flex;
                justify-content: center;
            }
            .balance-amount {
                width: 80%;
                margin: 0 auto;
                background-color: #EDA135;
                border-radius: 19px;
                padding: 36px;
                .logo {
                    .image {
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        background-color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 0 auto;
                    }
                    .name {
                        text-align: center;
                        font-size: 28px;
                        font-family: ChalkboardSEBold;
                    }
                }
                .form {
                    width: 70%;
                    margin: 0 auto;
                    .input {
                        .el-input-group {
                            display: flex!important;
                        }
                        .el-input .el-input__inner {
                            border-right: none;
                            border-radius: 13px 0 0  13px;
                            display: flex;
                            width: 80%;
                        }
                        .el-input-group__append {
                            display: flex;
                            align-items: center;
                            width: 50px;
                        }
                        .max-button {
                            background-color: #FDCB5B;
                            font-size: 14px;
                            height: 28px;
                            line-height: 22px;
                            margin-right: 2px;
                        }
                    }
                    .balance {
                        display: flex;
                        justify-content: space-between;
                        padding: 12px 0;
                        .total {
                            align-items: center;
                            font-family: ChalkboardSEBold;
                            font-size: 18px;
                        }
                    }
                }
            }
            .select-chain {
                display: flex;
                width: 74%;
                justify-content: space-between;
                margin: 0 auto;
                padding: 24px 0;
                .item {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    .type {
                        font-family: ChalkboardSEBold;
                        font-size: 20px;
                        padding-bottom: 12px;
                    }
                    .logo {
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        /*background-color: #fff;*/
                        margin: 0 auto;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .name {
                        font-family: ChalkboardSEBold;
                        padding: 8px 0;
                    }
                }
                .direction {
                    box-sizing: border-box;
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-bottom: 32px;
                    span {
                        height: 10px;
                        width: 100px;
                        background-color: #fff;
                    }
                    div {
                        width: 0;
                        height: 0;
                        border-top: 16px solid transparent;
                        border-left: 32px solid #fff;
                        border-bottom: 16px solid transparent;
                    }
                }
            }
            .submit {
                display: flex;
                justify-content: center;
            }
        }
        .withdraw-list {
            width: 80%;
            margin: 24px auto 0 auto;
            padding: 24px;
            border: solid 1px #e19a2c;
            border-radius: 15px;
            .list-header {
                border-bottom: solid 1px #e19a2c;
                display: flex;
                margin-bottom: 9px;
                span {
                    flex: 1;
                    padding-bottom: 12px;
                    box-sizing: border-box;
                }
            }
            .withdraw-item {
                display: flex;
                padding: 6px 0;
                > div {
                    flex: 2;
                    display: flex;
                    align-items: center;
                    font-family: ChalkboardSEBold;
                }
                .amount {
                    justify-content: flex-end;
                    padding-right: 24px;
                    flex: 1.6;
                }
                .progress {
                    margin-right: 6px;
                    div {
                        height: 11px;
                        width: 100%;
                        background-color: #fdcb5b;
                        border-radius: 6px;
                        padding: 3px;
                        box-sizing: border-box;
                        display: flex;
                        span {
                            width: 0%;
                            height: 5px;
                            background-color: #e19a2c;
                            border-radius: 6px;
                            border-right-width: 12px;
                            transition: width 15s;
                        }
                    }
                }
                .button {
                    justify-content: flex-end;
                    button {
                        font-size: 14px;
                        height: 28px;
                        line-height: 26px;
                    }
                }
            }
        }
    }
}
@keyframes train {
    0% {
        transform: translateX(-3544px);
    }
    100% {
        transform: translateX(2600px);
    }
}

@keyframes liuxing01 {
    0% {
        transform: translate(0, 0);
        opacity: 1;
    }
    30% {
        transform: translate(500px, 500px);
        opacity: 0;
    }
    100% {
        transform: translate(1000px, 1000px);
        opacity: 0;
    }
}

@keyframes liuxing02 {
    0% {
        transform: translate(0, 0);
        opacity: 1;
    }
    20% {
        transform: translate(500px, 500px);
        opacity: 0;
    }
    100% {
        transform: translate(600px, 600px);
        opacity: 0;
    }
}

@keyframes liuxing03 {
    0% {
        transform: translate(0, 0);
        opacity: 1;
    }
    40% {
        transform: translate(500px, 500px);
        opacity: 0;
    }
    100% {
        transform: translate(500px, 500px);
        opacity: 0;
    }
}
.home-product-dialog {
    display: flex;
    flex-direction: column;
    .icon {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 188px;
        position: relative;
        img {
            width: 188px;
            height: 188px;
        }
        .buy-count {
            position: absolute;
            right: 0;
            top: 0;
            color: #fff;
            font-size: 22px;
        }
    }
    .stake-content {
        color: #fff;
        font-size: 18px;
        text-align: center;
        font-family: ChalkboardSEBold;
        .apr {
            color: #000;
            padding-top: 24px;
            b {
                font-size: 28px;
            }
        }
    }
    .buy-content {
        font-size: 22px;
        text-align: center;
        color: #fff;
        .stc {
            font-family: ChalkboardSEBold;
        }
        .tip {
            font-size: 18px;
        }
    }
    .name {
        font-family: ChalkboardSEBold;
        font-size: 24px;
        color: #fff;
        text-align: center;
    }
    .star {
        font-family: ChalkboardSEBold;
        font-size: 36px;
        color: #fff;
        display: flex;
        justify-content: center;
    }
}
</style>
