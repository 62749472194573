<template>
    <div class="page-history-tools">
        <div class="tools-content">
            <history-pools />
            <div class="tools-content-bg"></div>
        </div>
    </div>
</template>

<script>
import historyPools from '../../../components/tools/history-pools'
export default {
    name: 'Home',
    components: {
        historyPools
    }
}
</script>

<style lang="less">
.page-history-tools {
    display: flex;
    max-width: 1920px;
    margin: 0 auto;
    flex-direction: column;
    .history-bar {
        flex: 1;
        .back-button {
            font-size: 24px;
            color: #fff;
        }
    }
    .tools-content {
        width: 860px;
        min-height: 80Vh;
        margin: 36px auto 0 auto;
        position: relative;
        .history-pools-container {
            background-color: #F7B746;
            border-radius: 13px;
            padding: 24px;
            width: 100%;
            .history-content {
                display: flex;
                flex-direction: column;
                .history-item {
                    display: flex;
                    border-bottom: solid 1px #FDCB5B;
                    padding: 24px 0;
                    &:last-of-type {
                        border-bottom: none;
                    }
                    .logo {
                        img {
                            width: 100px;
                            height: 100px;
                        }
                    }
                    .content {
                        flex: 1;
                        display: flex;
                        flex-wrap: wrap;
                        padding: 12px 24px;
                        .sub-item {
                            width: 50%;
                            display: flex;
                            align-items: center;
                            label {
                                color: #fff;
                                padding-right: 12px;
                            }
                            div {
                                font-family: ChalkboardSEBold;
                                color: #fff;
                                font-size: 16px;
                            }
                        }
                    }
                    .buttons {
                        display: flex;
                        align-items: center;
                        button {
                            height: 28px;
                            border-radius: 9px;
                            line-height: 26px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    .empty {
        width: 100%;
        height: 60vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
