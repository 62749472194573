<template>
    <div class="page-tools-mobile">
        <div class="tools-content">
            <content01 />
            <content02 />
            <content03 />
            <div class="tools-content-bg"></div>
        </div>
    </div>
</template>

<script>
import content01 from '../../../components/tools/content-01'
import content02 from '../../../components/tools/content-02'
import content03 from '../../../components/tools/content-03'
export default {
    name: 'Home',
    components: {
        content01,
        content02,
        content03
    }
}
</script>

<style lang="less">
.page-tools-mobile {
    display: flex;
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
    .tools-content {
        width: 414px;
        height: 2720px;
        background-image: url("../../../assets/images/mobile/tools-bg-01.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
        margin: 36px auto 0 auto;
        position: relative;
        .tools-content-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../../../assets/images/mobile/tools-bg-02.png");
            background-repeat: no-repeat;
            background-size: 100% auto;
            pointer-events: none;
            z-index: 11;
        }
        .tools-content-01 {
            display: flex;
            flex-direction: column;
            position: relative;
            height: 30.6rem;
            width: 100%;
            overflow: hidden;
            .title {
                display: flex;
                justify-content: center;
                position: absolute;
                width: 100%;
                top: 4rem;
                img {
                    width: 50%;
                    height: 50%;
                }
            }
            .train {
                position: absolute;
                bottom: 0;
                z-index: 8;
                width: 100%;
                left: 50%;
                margin-left: -60%;
            }
            .content-01 {
                height: 31.5rem;
                width: 100%;
                overflow: hidden;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                z-index: 1;
            }
            .content {
                position: relative;
                width: 100%;
                .token-info {
                    border: solid 3px #fff;
                    background-color: #E8B12A;
                    padding:1.4rem 1rem .6rem 1rem;
                    border-radius: 13px;
                    margin: 0 auto;
                    color: #fff;
                    font-weight: bold;
                    position: relative;
                    width: 70%;
                    text-align: center;
                    .icon {
                        position: absolute;
                        background-color: #8DC351;
                        /*border: solid 2px #fff;*/
                        width: 4rem;
                        height: 4rem;
                        border-radius: 50%;
                        top: -2rem;
                        left: 1.4rem;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .tips {
                        font-size: 18px;
                        text-align: center;
                        padding-top: 0.2rem;
                        padding-bottom: 2px;
                    }
                    .amount {
                        font-size: 22px;
                        color: #000;
                        background-color: #fff;
                        border-radius: 0.6rem;
                        padding: 6px 12px;
                        text-align: center;
                    }
                    h2 {
                        text-align: center;
                        font-size: 18px;
                        font-family: ChalkboardBold;
                    }
                    .history-button {
                        padding: 8px 0 4px 0;
                        display: flex;
                        justify-content: center;
                        span {
                            height: 24px;
                            line-height: 24px;
                            font-size: 14px;
                            /*color: rgba(255, 255, 255, 0.5);*/
                            /*text-decoration-line: underline;*/
                            /*text-decoration-color: rgba(255, 255, 255, 0.5);*/
                            color: #fff;
                            border: solid 1px #fff;
                            padding: 0 16px;
                            border-radius: 9px;
                        }
                    }
                }
                .count-down {
                    background-color: #000;
                    margin: 0 auto;
                    padding: 0 8px;
                    color: #fff;
                    width: 70%;
                    .tip {
                        line-height: 32px;
                        text-align: center;
                        font-size: 18px;
                    }
                    .time {
                        background-color: #232323;
                        height: 2.4rem;
                        display: flex;
                        b {
                            font-size: 24px;
                        }
                    }
                    .time-tip {
                        display: flex;
                        span {
                            flex: 1;
                            text-align: center;
                            line-height: 24px;
                        }
                    }
                }
                .line-02 {
                    width: 70%;
                    display: flex;
                    justify-content: space-between;
                    min-height: 12px;
                    margin: 0 auto;
                    span {
                        width: 22px;
                        background-color: #898989;
                        border-style: solid;
                        border-color: #000;
                        border-width: 0 3px;
                        &:first-of-type {
                            margin-left: 82px;
                        }
                        &:last-of-type {
                            margin-right: 82px;
                        }
                    }
                    &:last-of-type {
                        height: 4.5rem!important;
                    }
                }
            }
        }
        .tools-content-02 {
            display: flex;
            flex-direction: column;
            position: relative;
            height: 1416px;
            width: 100%;
            overflow: hidden;
            .title {
                display: flex;
                justify-content: center;
                position: absolute;
                width: 100%;
                top: 6rem;
                img {
                    width: 20%;
                    height: 20%;
                }
            }
            .content {
                display: flex;
                justify-content: center;
                width: 100%;
                flex-direction: column;
                color: #fff;
                .left-button {
                    position: absolute;
                    left: 0;
                    top: 316px;
                    cursor: pointer;
                    opacity: 0.6;
                    z-index: 99;
                    &:hover {
                        opacity: 1;
                    }
                    img {
                        width: 50%;
                    }
                }
                .right-button {
                    position: absolute;
                    right: 0;
                    top: 316px;
                    transform: rotate(180deg);
                    cursor: pointer;
                    opacity: 0.6;
                    z-index: 99;
                    &:hover {
                        opacity: 1;
                    }
                    img {
                        width: 50%;
                    }
                }
                .carousel {
                    width: 100%;
                    overflow: hidden;
                    margin: 0 auto;
                    padding: 90px 0 24px 0;
                }
                .carousel-content {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    transform: translateX(0);
                    transition: transform 0.6s;
                    &.carousel-01 {
                        transform: translateX(0);
                    }
                    &.carousel-02 {
                        transform: translateX(-100vw);
                    }
                    &.carousel-03 {
                        transform: translateX(-200vw);
                    }
                }
                .carousel-stake {
                    width: 100%;
                    margin: 0 auto;
                    overflow: hidden;
                    position: relative;
                    padding-top: 14px;
                }
                .carousel-stake-content {
                    width: 300%;
                    display: flex;
                    justify-content: center;
                    transform: translateX(0);
                    transition: transform 0.6s;
                    &.carousel-stake-01 {
                        transform: translateX(0);
                    }
                    &.carousel-stake-02 {
                        transform: translateX(-100vw);
                    }
                    &.carousel-stake-03 {
                        transform: translateX(-200vw);
                    }
                }
                .mining-rewards {
                    background-color: rgba(253, 203, 91, 0.3);
                    margin-top: 36px;
                    position: relative;
                    padding: 24px;
                    .rewards-title {
                        display: flex;
                        align-items: center;
                        height: 24px;
                        position: absolute;
                        left: 0;
                        width: 100%;
                        top: -13px;
                        * {
                            flex: 1;
                        }
                        em {
                            height: 3px;
                            background-color: #fff;
                        }
                        span {
                            white-space: nowrap;
                            padding: 0 12px;
                            line-height: 24px;
                        }
                    }
                    .tools-item {
                        display: flex;
                        flex-direction: column;
                        padding-bottom: 16px;
                        label {
                            color: rgba(255, 255, 255, 0.4);
                            font-family: Chalkboard;
                            span {
                                font-size: 14px;
                            }
                        }
                        span {
                            font-size: 20px;
                        }
                    }
                    .mining-item {
                        display: flex;
                        justify-content: space-between;
                        padding-bottom: 16px;
                    }
                    .mining-button {
                        padding: 0 36px;
                        display: flex;
                        button {
                            background-color: #2C99F1;
                            height: 42px;
                            border-radius: 5px;
                            color: #fff;
                            font-family: ChalkboardSEBold;
                            font-size: 18px;
                            cursor: pointer;
                            flex: 1;
                        }
                    }
                }
                .tools-box-list {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    position: relative;
                    margin: 0 auto;
                    width: 100%;
                    padding-top: 80px;
                    height: 678px;
                    .info-item {
                        max-width: calc(100vw - 96px);
                    }
                    .title {
                        top: 60px;
                        img {
                            width: 30%;
                            height: 30%;
                        }
                    }
                    .name {
                        position: relative!important;
                        margin-top: 70px!important;
                    }
                    .icon {
                        position: absolute!important;
                        top: -75px;
                        left: 50%;
                        margin-left: -75px!important;
                        .star {
                            top: 0!important;
                            right: -10px!important;
                        }
                    }
                    .mining-rewards {
                        margin-top: 20px;
                        padding-bottom: 38px;
                    }
                    .level {
                        font-size: 32px;
                    }
                    .button {
                        position: absolute;
                        bottom: -20px;
                        z-index: 1111;
                    }
                }
                .tools-stake-list {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    position: relative;
                    margin: 60px auto 0 auto;
                    width: 100%;
                    padding-top: 4rem;
                    height: 678px;
                    .title {
                        top: 0;
                    }
                }
                .info-item {
                    border: solid 3px #fff;
                    border-radius: 15px;
                    position: relative;
                    padding: 24px 0 0 0;
                    margin: 0 48px;
                    text-align: center;
                    font-size: 16px;
                    /*max-width: 293px;*/
                    flex: 1;
                    color: #fff;
                    font-family: ChalkboardBold;
                    background-color: rgba(229, 148, 41, 0.6);
                    .icon {
                        width: 150px;
                        height: 150px;
                        margin: 0 auto;
                        position: relative;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                        .star {
                            display: flex;
                            justify-content: center;
                            font-size: 22px;
                            font-family: ChalkboardBold;
                            padding: 24px 0;
                            position: absolute;
                            top: -10px;
                            right: -10px;
                        }
                    }
                    .name {
                        font-size: 22px;
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        padding: 0 24px;
                        box-sizing: border-box;
                        display: flex;
                        justify-content: center;
                        span {
                            position: absolute;
                            top: -16px;
                            left: 0;
                            width: 100%;
                            text-align: center;
                            z-index: 2;
                        }
                        em {
                            color: transparent;
                            position: relative;
                            top: -3px;
                            border-top: solid 3px #e19a2c;
                            z-index: 0;
                            padding: 0 16px;
                        }
                    }
                    .count {
                        margin: 24px auto 4px auto;
                        width: 70%;
                    }
                    .button {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        padding: 12px 60px 0 60px;
                        box-sizing: border-box;
                        button {
                            background-color: #2C99F1;
                            height: 42px;
                            border-radius: 5px;
                            color: #fff;
                            font-family: ChalkboardSEBold;
                            font-size: 18px;
                            cursor: pointer;
                            flex: 1;
                        }
                    }
                }
                .empty {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 6rem;
                    font-size: 18px;
                    margin: 0 auto;
                    height: 100%;
                    p {
                        width: 100%;
                    }
                    div {
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding-bottom: 2rem;
                    }
                }
            }
        }
        .tools-content-03 {
            display: flex;
            flex-direction: column;
            position: relative;
            height: 728px;
            width: 100%;
            overflow: hidden;
            .title {
                display: flex;
                justify-content: center;
                position: absolute;
                width: 100%;
                top: 60px;
                img {
                    width: 20%;
                    height: 20%;
                }
            }
            .content {
                display: flex;
                flex-direction: column;
                width: 80%;
                margin: 120px auto 0 auto;
                color: #fff;
                .empty {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 22rem;
                    text-align: center;
                }
                .rebate-content {
                    width: 100%;
                    max-height: 450px;
                    overflow: auto;
                }
                .item {
                    border-bottom: solid 1px #fff;
                    padding: 12px 0;
                    font-size: 18px;
                    position: relative;
                    text-align: center;
                    display: flex;
                    &.disabled {
                        color: rgba(255, 255, 255, 0.2);
                    }
                    > * {
                        flex: 1;
                    }
                    span {
                        font-size: 14px;
                        color: #fff;
                        white-space: nowrap;
                    }
                    div {
                        font-family: ChalkboardSEBold;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 16px;
                    }
                    button {
                        background-color: #2C99F1;
                        padding: 0 24px;
                        height: 28px;
                        line-height: 24px;
                        border-radius: 9px;
                        color: #fff;
                        font-size: 14px;
                        font-family: ChalkboardSEBold;
                        cursor: pointer;
                        span {
                            color: #fff;
                        }
                    }
                }
                .buttons {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .tips {
                        font-size: 18px;
                        text-align: center;
                        padding: 16px 0 4px 0;
                    }
                    button {
                        width: 30%;
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 375px) {
    .page-tools-mobile {
        display: flex;
        width: 100%;
        margin: 0 auto;
        flex-direction: column;
        .tools-content {
            width: 360px;
            height: 2380px;
            background-image: url("../../../assets/images/mobile/tools-bg-01.png");
            background-repeat: no-repeat;
            background-size: 100% auto;
            margin: 36px auto 0 auto;
            position: relative;
            .tools-content-bg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: url("../../../assets/images/mobile/tools-bg-02.png");
                background-repeat: no-repeat;
                background-size: 100% auto;
                pointer-events: none;
                z-index: 11;
            }
            .tools-content-01 {
                display: flex;
                flex-direction: column;
                position: relative;
                height: 26.6rem;
                width: 100%;
                overflow: hidden;
                .title {
                    display: flex;
                    justify-content: center;
                    position: absolute;
                    width: 100%;
                    top: 3.4rem;
                    img {
                        width: 34%;
                        height: 34%;
                    }
                }
                .train {
                    position: absolute;
                    bottom: 0;
                    z-index: 8;
                    width: 100%;
                    left: 50%;
                    margin-left: -60%;
                }
                .content-01 {
                    height: 31.5rem;
                    width: 100%;
                    overflow: hidden;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    z-index: 1;
                }
                .content {
                    position: relative;
                    width: 100%;
                    .token-info {
                        border: solid 3px #fff;
                        background-color: #E8B12A;
                        padding:1.4rem 1rem .2rem 1rem;
                        border-radius: 13px;
                        margin: 0 auto;
                        color: #fff;
                        font-weight: bold;
                        position: relative;
                        width: 70%;
                        text-align: center;
                        .icon {
                            position: absolute;
                            background-color: #8DC351;
                            /*border: solid 2px #fff;*/
                            width: 3.4rem;
                            height: 3.4rem;
                            border-radius: 50%;
                            top: -1.7rem;
                            left: 1.4rem;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .tips {
                            font-size: 16px;
                            text-align: center;
                            padding-top: 0.2rem;
                            padding-bottom: 2px;
                        }
                        .amount {
                            font-size: 18px;
                            color: #000;
                            background-color: #fff;
                            border-radius: 0.6rem;
                            padding: 6px 0;
                            text-align: center;
                        }
                        h2 {
                            text-align: center;
                            font-size: 18px;
                            font-family: ChalkboardBold;
                        }
                    }
                    .count-down {
                        background-color: #000;
                        margin: 0 auto;
                        padding: 0 8px;
                        color: #fff;
                        width: 70%;
                        .tip {
                            line-height: 32px;
                            text-align: center;
                            font-size: 18px;
                        }
                        .time {
                            background-color: #232323;
                            height: 2.4rem;
                            display: flex;
                            b {
                                font-size: 24px;
                            }
                        }
                        .time-tip {
                            display: flex;
                            span {
                                flex: 1;
                                text-align: center;
                                line-height: 24px;
                            }
                        }
                    }
                    .line-02 {
                        width: 70%;
                        display: flex;
                        justify-content: space-between;
                        min-height: 12px;
                        margin: 0 auto;
                        span {
                            width: 22px;
                            background-color: #898989;
                            border-style: solid;
                            border-color: #000;
                            border-width: 0 3px;
                            &:first-of-type {
                                margin-left: 82px;
                            }
                            &:last-of-type {
                                margin-right: 82px;
                            }
                        }
                        &:last-of-type {
                            height: 4rem!important;
                        }
                    }
                }
            }
            .tools-content-02 {
                display: flex;
                flex-direction: column;
                position: relative;
                height: 1236px;
                width: 100%;
                overflow: hidden;
                .title {
                    display: flex;
                    justify-content: center;
                    position: absolute;
                    width: 100%;
                    top: 4rem;
                    img {
                        width: 24%;
                        height: 24%;
                    }
                }
                .content {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    flex-direction: column;
                    color: #fff;
                    .left-button {
                        position: absolute;
                        left: 0;
                        top: 266px;
                        cursor: pointer;
                        opacity: 0.6;
                        z-index: 99;
                        &:hover {
                            opacity: 1;
                        }
                        img {
                            width: 50%;
                        }
                    }
                    .right-button {
                        position: absolute;
                        right: 0;
                        top: 266px;
                        transform: rotate(180deg);
                        cursor: pointer;
                        opacity: 0.6;
                        z-index: 99;
                        &:hover {
                            opacity: 1;
                        }
                        img {
                            width: 50%;
                        }
                    }
                    .carousel {
                        width: 100%;
                        overflow: hidden;
                        margin: 0 auto;
                        padding: 90px 0 24px 0;
                    }
                    .carousel-content {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        transform: translateX(0);
                        transition: transform 0.6s;
                        &.carousel-01 {
                            transform: translateX(0);
                        }
                        &.carousel-02 {
                            transform: translateX(-100vw);
                        }
                        &.carousel-03 {
                            transform: translateX(-200vw);
                        }
                    }
                    .carousel-stake {
                        width: 100%;
                        margin: 0 auto;
                        overflow: hidden;
                        position: relative;
                        padding-top: 14px;
                    }
                    .carousel-stake-content {
                        width: 300%;
                        display: flex;
                        justify-content: center;
                        transform: translateX(0);
                        transition: transform 0.6s;
                        &.carousel-stake-01 {
                            transform: translateX(0);
                        }
                        &.carousel-stake-02 {
                            transform: translateX(-100vw);
                        }
                        &.carousel-stake-03 {
                            transform: translateX(-200vw);
                        }
                    }
                    .mining-rewards {
                        background-color: rgba(253, 203, 91, 0.3);
                        margin-top: 26px;
                        position: relative;
                        padding: 24px;
                        .rewards-title {
                            display: flex;
                            align-items: center;
                            height: 24px;
                            position: absolute;
                            left: 0;
                            width: 100%;
                            top: -13px;
                            * {
                                flex: 1;
                            }
                            em {
                                height: 3px;
                                background-color: #fff;
                            }
                            span {
                                white-space: nowrap;
                                padding: 0 12px;
                                line-height: 24px;
                            }
                        }
                        .tools-item {
                            display: flex;
                            flex-direction: column;
                            padding-bottom: 8px;
                            label {
                                color: rgba(255, 255, 255, 0.4);
                                font-family: Chalkboard;
                                span {
                                    font-size: 14px;
                                }
                            }
                            span {
                                font-size: 18px;
                            }
                        }
                        .mining-item {
                            display: flex;
                            justify-content: space-between;
                            padding-bottom: 8px;
                        }
                        .mining-button {
                            padding: 0 36px;
                            display: flex;
                            button {
                                background-color: #2C99F1;
                                height: 42px;
                                border-radius: 5px;
                                color: #fff;
                                font-family: ChalkboardSEBold;
                                font-size: 18px;
                                cursor: pointer;
                                flex: 1;
                            }
                        }
                    }
                    .empty {
                        text-align: center;
                        font-size: 18px;
                    }
                    .tools-box-list {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        position: relative;
                        margin: 0 auto;
                        width: 100%;
                        padding-top: 20px;
                        height: 638px;
                        .info-item {
                            max-width: calc(100vw - 96px);
                        }
                        .title {
                            top: 0px;
                            img {
                                width: 40%;
                                height: 40%;
                            }
                        }
                        .name {
                            position: relative!important;
                            margin-top: 80px!important;
                        }
                        .icon {
                            position: absolute!important;
                            top: -75px;
                            left: 50%;
                            margin-left: -75px!important;
                            .star {
                                top: 0!important;
                                right: -10px!important;
                            }
                        }
                        .mining-rewards {
                            margin-top: 10px;
                            padding-bottom: 30px;
                        }
                        .level {
                            font-size: 32px;
                        }
                        .button {
                            position: absolute;
                            bottom: -20px;
                            z-index: 1111;
                        }
                    }
                    .tools-stake-list {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        position: relative;
                        margin: 50px auto 0 auto;
                        width: 100%;
                        padding-top: 3rem;
                        height: 648px;
                        .title {
                            top: 0;
                        }
                    }
                    .info-item {
                        border: solid 3px #fff;
                        border-radius: 15px;
                        position: relative;
                        padding: 12px 0 0 0;
                        margin: 0 48px;
                        text-align: center;
                        font-size: 16px;
                        /*max-width: 293px;*/
                        flex: 1;
                        color: #fff;
                        font-family: ChalkboardBold;
                        background-color: rgba(229, 148, 41, 0.6);
                        .icon {
                            width: 150px;
                            height: 150px;
                            margin: 0 auto;
                            position: relative;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                            .star {
                                display: flex;
                                justify-content: center;
                                font-size: 22px;
                                font-family: ChalkboardBold;
                                padding: 24px 0;
                                position: absolute;
                                top: -10px;
                                right: -10px;
                            }
                        }
                        .name {
                            font-size: 22px;
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            padding: 0 24px;
                            box-sizing: border-box;
                            display: flex;
                            justify-content: center;
                            span {
                                position: absolute;
                                top: -16px;
                                left: 0;
                                width: 100%;
                                text-align: center;
                                z-index: 2;
                            }
                            em {
                                color: transparent;
                                position: relative;
                                top: -3px;
                                border-top: solid 3px #e19a2c;
                                z-index: 0;
                                padding: 0 16px;
                            }
                        }
                        .count {
                            margin: 4px auto 4px auto;
                            width: 70%;
                        }
                        .button {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            padding: 12px 60px 0 60px;
                            box-sizing: border-box;
                            button {
                                background-color: #2C99F1;
                                height: 42px;
                                border-radius: 5px;
                                color: #fff;
                                font-family: ChalkboardSEBold;
                                font-size: 18px;
                                cursor: pointer;
                                flex: 1;
                            }
                        }
                    }
                }
            }
            .tools-content-03 {
                display: flex;
                flex-direction: column;
                position: relative;
                height: 728px;
                width: 100%;
                overflow: hidden;
                .title {
                    display: flex;
                    justify-content: center;
                    position: absolute;
                    width: 100%;
                    top: 60px;
                    img {
                        width: 26%;
                        height: 26%;
                    }
                }
                .content {
                    display: flex;
                    flex-direction: column;
                    width: 80%;
                    margin: 90px auto 0 auto;
                    color: #fff;
                    .rebate-content {
                        width: 100%;
                        max-height: 380px;
                        overflow: auto;
                    }
                    .item {
                        border-bottom: solid 1px #fff;
                        padding: 12px 0;
                        font-size: 18px;
                        position: relative;
                        text-align: center;
                        display: flex;
                        &.disabled {
                            color: rgba(255, 255, 255, 0.2);
                        }
                        > * {
                            flex: 1;
                        }
                        span {
                            font-size: 14px;
                            color: #fff;
                        }
                        div {
                            font-family: ChalkboardSEBold;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 16px;
                        }
                        button {
                            background-color: #2C99F1;
                            padding: 0 24px;
                            height: 28px;
                            line-height: 24px;
                            border-radius: 9px;
                            color: #fff;
                            font-size: 14px;
                            font-family: ChalkboardSEBold;
                            cursor: pointer;
                            span {
                                color: #fff;
                            }
                        }
                    }
                    .buttons {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        .tips {
                            font-size: 18px;
                            text-align: center;
                            padding: 16px 0 4px 0;
                        }
                        button {
                            width: 40%;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }
}
</style>
