import BSCICONN_LOGO from '../assets/logo/BSC.png'
import HECO_LOGO from '../assets/logo/HECO.png'
import BSC_LOGO from '../assets/logo/BSC.png'
import ETH_LOGO from '../assets/logo/ETH.png'
import ADA from '../assets/logo/ada.png'
import BCH from '../assets/logo/bch.png'
import BSV from '../assets/logo/bsv.png'
import DOGE from '../assets/logo/doge.png'
import MNC from '../assets/logo/mnc.png'
import BRIDGE from '../assets/logo/bsv.png'
import REWARD from '../assets/logo/doge.png'
import WEN from '../assets/logo/wen.png'

export const poolLogos = {
    ADA,
    BCH,
    BSV,
    DOGE,
    MNC,
    BRIDGE,
    REWARD,
    WEN
}

export const local = {
    LOCALE: 'locale'
}

// export const mncPair = {
//     HECO: '0xdBeB3d1381a0B3Ad0518E744d27Cd0896Bb1e078',
//     BSC: '0xb64d22686aFe838455345Ed7271C4C570B854FDA'
// }

const chain_a = JSON.parse(process.env.VUE_APP_CHAIN_A)
const chain_b = JSON.parse(process.env.VUE_APP_CHAIN_B)
const bridge_a = JSON.parse(process.env.VUE_APP_BRIDGE_A)
const bridge_b = JSON.parse(process.env.VUE_APP_BRIDGE_B)

export const tokenName = bridge_a.TOKEN_CODE

export const chainList = process.env.VUE_APP_NODE_ENV === 'production' ? {
    HECO: {
        name: 'HECO',
        icon: HECO_LOGO
    },
    BSC: {
        name: 'BSC',
        icon: BSCICONN_LOGO
    }
} : {
    HECO_TESTNET: {
        name: 'HECO_TESTNET',
        icon: HECO_LOGO
    },
    BSC_TESTNET: {
        name: 'BSC_TESTNET',
        icon: BSCICONN_LOGO
    }
}

export const ChainIds = {
    MAINNET: 1,
    ROPSTEN: 3,
    RINKEBY: 4,
    GÖRLI: 5,
    KOVAN: 42,
    MATIC: 137,
    MATIC_TESTNET: 80001,
    FANTOM: 250,
    FANTOM_TESTNET: 4002,
    XDAI: 100,
    BSC: 56,
    BSC_TESTNET: 97,
    ARBITRUM: 79377087078960,
    MOONBASE: 1287,
    AVALANCHE: 43114,
    FUJI: 43113,
    HECO: 128,
    HECO_TESTNET: 256,
    HARMONY: 1666600000,
    HARMONY_TESTNET: 1666700000,
    OKEX: 66,
    OKEX_TESTNET: 65
}

export const ChainName = {
    1: 'MAINNET',
    3: 'ROPSTEN',
    4: 'RINKEBY',
    5: 'GÖRLI',
    42: 'KOVAN',
    137: 'MATIC',
    80001: 'MATIC_TESTNET',
    250: 'FANTOM',
    4002: 'FANTOM_TESTNET',
    100: 'XDAI',
    56: 'BSC',
    97: 'BSC_TESTNET',
    79377087078960: 'ARBITRUM',
    1287: 'MOONBASE',
    43114: 'AVALANCHE',
    43113: 'FUJI',
    128: 'HECO',
    256: 'HECO_TESTNET',
    1666600000: 'HARMONY',
    1666700000: 'HARMONY_TESTNET',
    66: 'OKEX',
    65: 'OKEX_TESTNET',
    '0x100': 'HECO_TESTNET',
    '0x61': 'BSC_TESTNET',
    '0x3': 'ROPSTEN',
    '0x80': 'HECO',
    '0x38': 'BSC'
}

export const netWork = {
    HECO: {
        chainId: `0x80`,
        chainName: 'HECO',
        nativeCurrency: {
            name: 'HT',
            symbol: 'HT',
            decimals: 18
        },
        rpcUrls: ["https://http-mainnet.hecochain.com"],
        blockExplorerUrls: ['https://hecoinfo.com']
    },
    BSC: {
        chainId: `0x38`,
        chainName: 'BSC',
        nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18
        },
        rpcUrls: ["https://bsc-dataseed.binance.org"],
        blockExplorerUrls: ['https://bscscan.com']
    },
    HECO_TESTNET: {
        chainId: `0x100`,
        chainName: 'Heco Test',
        nativeCurrency: {
            name: 'HT',
            symbol: 'HT',
            decimals: 18
        },
        rpcUrls: ["https://http-testnet.hecochain.com"],
        blockExplorerUrls: ['https://testnet.hecoinfo.com']
    },
    BSC_TESTNET: {
        chainId: `0x61`,
        chainName: 'Bsc Test',
        nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18
        },
        rpcUrls: ["https://data-seed-prebsc-2-s3.binance.org:8545"],
        blockExplorerUrls: ['https://testnet.bscscan.com']
    },
    ROPSTEN: {
        chainId: '0x3',
        chainName: 'Ropsten',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18
        },
        rpcUrls: ["https://ropsten.infura.io/v3/ee13e33dbf184e508cb29b99c808f43f"],
        blockExplorerUrls: ['https://ropsten.etherscan.io/']
    }
}

export const ADDRESS = {
    HECO_TESTNET: {
        erc20mock: chain_a.ERC20,
        minersnfts: chain_a.ERC1155,
        mncnftstore: chain_a.NFT_STORE,
        stakingpools: (v) => {
            v = v || 0
            return chain_a.STAKING[v].POOL
        },
        stakingpoolrewarder: (v) => {
            v = v || 0
            return chain_a.STAKING[v].REWARDER
        },
        bridge: bridge_a.BRIDGE,
        staking: chain_a.STAKING,
        pair: chain_a.ERC20_TICKER,
        bridgeRelayerUrl: bridge_a.RELAYER_URL
    },
    BSC_TESTNET: {
        erc20mock: chain_b.ERC20,
        minersnfts: chain_b.ERC1155,
        mncnftstore: chain_b.NFT_STORE,
        stakingpools: (v) => {
            v = v || 0
            return chain_b.STAKING[v].POOL
        },
        stakingpoolrewarder: (v) => {
            v = v || 0
            return chain_b.STAKING[v].REWARDER
        },
        bridge: bridge_b.BRIDGE,
        staking: chain_b.STAKING,
        pair: chain_b.ERC20_TICKER,
        bridgeRelayerUrl: bridge_b.RELAYER_URL
    },
    HECO: {
        erc20mock: chain_a.ERC20,
        minersnfts: chain_a.ERC1155,
        mncnftstore: chain_a.NFT_STORE,
        stakingpools: (v) => {
            v = v || 0
            return chain_a.STAKING[v].POOL
        },
        stakingpoolrewarder: (v) => {
            v = v || 0
            return chain_a.STAKING[v].REWARDER
        },
        bridge: bridge_a.BRIDGE,
        staking: chain_a.STAKING,
        pair: chain_a.ERC20_TICKER,
        bridgeRelayerUrl: bridge_a.RELAYER_URL
    },
    BSC: {
        erc20mock: chain_b.ERC20,
        minersnfts: chain_b.ERC1155,
        mncnftstore: chain_b.NFT_STORE,
        stakingpools: (v) => {
            v = v || 0
            return chain_b.STAKING[v].POOL
        },
        stakingpoolrewarder: (v) => {
            v = v || 0
            return chain_b.STAKING[v].REWARDER
        },
        bridge: bridge_b.BRIDGE,
        staking: chain_b.STAKING,
        pair: chain_b.ERC20_TICKER,
        bridgeRelayerUrl: bridge_b.RELAYER_URL
    }
}

export const IPFSURL =  process.env.VUE_APP_NODE_ENV === 'production' ? {
    'en-US' : 'https://metaminers.one/mnc.json',
    'zh-CN': 'https://metaminers.one/mnc_CN.json'
} : {
    'en-US' : 'api/mnc.json',
    'zh-CN': 'api/mnc_CN.json'
}

export const INITDATA = process.env.VUE_APP_NODE_ENV === 'production' ? {
        "1": "/ipfs/QmUtYKbHgW1H9zoJGqAykLt84g257K2PjbMmLczS6VrgGZ",
        "2": "/ipfs/QmUWrXo5Z63AZ44CcTFQ6QEy9zfPBGG6pxiECXXEv2Q1sq",
        "3": "/ipfs/QmcCX9Tgc1YvKjw9FeuLe1o8KHSVaMSjkpycfhiaUQ12pY"
    } :
    {
        "1": "/ipfs/QmUtYKbHgW1H9zoJGqAykLt84g257K2PjbMmLczS6VrgGZ",
        "2": "/ipfs/QmUWrXo5Z63AZ44CcTFQ6QEy9zfPBGG6pxiECXXEv2Q1sq",
        "3": "/ipfs/QmcCX9Tgc1YvKjw9FeuLe1o8KHSVaMSjkpycfhiaUQ12pY"
    }
