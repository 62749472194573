import Vue from 'vue'
import MinersNFTs from './MinersNFTs.json'
import MncNFTStore from './MncNFTStore.json'
import MockERC20 from './MockERC20.json'
import ERC20 from './ERC20.json'
import StakingPools from './StakingPools.json'
import StingPoolRewarder from './StakingPoolRewarder.json'
import BRIDGE from './BRIDGE.json'
import pair from './pair.json'
import aggregator from './aggregator.json'

const abi = {
  MinersNFTs,
  MncNFTStore,
  StakingPools,
  MockERC20,
  StingPoolRewarder,
  ERC20,
  BRIDGE,
  pair,
  aggregator
}

Vue.prototype.$abi = abi
