<template>
    <div class="page-home-pc">
        <div class="banner">
            <div class="bg1" />
            <div class="bg2" />
            <div class="train" />
            <div class="liuxing-01"></div>
            <div class="liuxing-02"></div>
            <div class="liuxing-03"></div>
            <banner />
        </div>
        <div class="home-content">
            <content01 />
            <content02 />
            <content03 />
        </div>
    </div>
</template>

<script>
import banner from '../../../components/home/banner'
import content01 from '../../../components/home/content-01'
import content02 from '../../../components/home/content-02'
import content03 from '../../../components/home/content-03'
export default {
    name: 'Home',
    components: {
        banner,
        content01,
        content02,
        content03
    }
}
</script>

<style lang="less">
.page-home-pc {
    display: flex;
    max-width: 1920px;
    margin: 0 auto;
    flex-direction: column;
    min-height: 669px;
    background-image: url("../../../assets/images/pc/banner.png");
    background-repeat: no-repeat;
    background-position: center top;
    .banner {
        height: 669px;
        width: 100%;
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        z-index: 1;
        .content {
            position: relative;
            .token-logo {
                width: 150px;
                height: 150px;
                /*border: solid 3px #fff;*/
                background-color: #E8B12A;
                border-radius: 50%;
                margin: 0 auto;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .line-01 {
                width: 22px;
                height: 12px;
                background-color: #898989;
                border-style: solid;
                border-color: #000;
                border-width: 0 3px;
                margin: 0 auto;
            }
            .token-info {
                border: solid 3px #fff;
                /*background-color: #E8B12A;*/
                background-color: #E8B12A;
                padding: 12px 24px;
                border-radius: 11px;
                margin: 0 auto;
                color: #fff;
                font-size: 24px;
                font-weight: bold;
                text-align: center;
                position: relative;
                h2 {
                    text-align: center;
                    font-size: 42px;
                    font-family: ChalkboardBold;
                }
                .tips {
                    position: absolute;
                    right: -60px;
                    top: 24px;
                    display: flex;
                    i {
                        background-color: #2C99F1;
                        border-radius: 50%;
                        width: 36px;
                        height: 36px;
                        font-style: normal;
                        align-items: center;
                        display: flex;
                        justify-content: center;
                        border: solid 3px #fff;
                        overflow: hidden;
                        font-size: 28px;
                    }
                    &.active {
                        .tip-content-bg {
                            display: block;
                        }
                        .tip-content {
                            display: block;
                        }
                    }
                    .tip-content-bg {
                        width: 100vw;
                        height: 100vh;
                        position: fixed;
                        top: 0;
                        left: 0;
                        z-index: 99;
                        display: none;
                    }
                    .tip-content {
                        position: absolute;
                        background-color: #2C99F1;
                        right: 50px;
                        top: -120px;
                        border: solid 3px #fff;
                        border-radius: 13px;
                        padding: 12px 24px;
                        display: none;
                        z-index: 100;
                        h2 {
                            font-size: 28px;
                        }
                        .info {
                            font-family: Chalkboard;
                            font-size: 16px;
                            white-space: nowrap;
                        }
                        .date-info {
                            p {
                                display: flex;
                                flex-direction: column;
                                padding-top: 16px;
                                label {
                                    font-size: 12px;
                                }
                                span {
                                    font-size: 16px;
                                }
                            }
                        }
                        .info-item {
                            padding: 12px;
                            border: solid 3px #fff;
                            border-radius: 13px;
                            width: 60%;
                            margin: 24px auto 0 auto;
                            .item {
                                display: flex;
                                justify-content: space-between;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
            .count-down {
                background-color: #000;
                margin: 0 auto;
                padding: 0 8px;
                color: #fff;
                .tip {
                    line-height: 32px;
                    text-align: center;
                    font-size: 18px;
                }
                .time {
                    background-color: #232323;
                    height: 56px;
                    display: flex;
                }
                .time-tip {
                    display: flex;
                    span {
                        flex: 1;
                        text-align: center;
                        line-height: 24px;
                    }
                }
            }
            .line-02 {
                width: 100%;
                display: flex;
                justify-content: space-between;
                min-height: 12px;
                span {
                    width: 22px;
                    background-color: #898989;
                    border-style: solid;
                    border-color: #000;
                    border-width: 0 3px;
                    &:first-of-type {
                        margin-left: 82px;
                    }
                    &:last-of-type {
                        margin-right: 82px;
                    }
                }
            }
        }
        .bg1 {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;
            background-image: url("../../../assets/images/pc/bg1.png");
            background-repeat: no-repeat;
            background-position: bottom center;
            pointer-events: none;
        }
        .bg2 {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            background-image: url("../../../assets/images/pc/bg2.png");
            background-repeat: no-repeat;
            background-position: bottom center;
            pointer-events: none;
        }
        .train {
            width: 3542px;
            height: 121px;
            background-image: url("../../../assets/images/pc/train.png");
            background-repeat:  repeat-x;
            background-position: center right;
            position: absolute;
            top: 531px;
            z-index: 2;
            transform: translateX(-3544px);
            animation: train 18s linear infinite;
        }
        .liuxing-01 {
            width: 215px;
            height: 185px;
            background: url("../../../assets/images/pc/liuxing-01.png") no-repeat;
            position: absolute;
            top: -185px;
            left: -215px;
            transform: translate(0, 0);
            animation: liuxing01 5s ease-in infinite;
            z-index: 0;
        }
        .liuxing-02 {
            width: 308px;
            height: 277px;
            background: url("../../../assets/images/pc/liuxing-02.png") no-repeat;
            position: absolute;
            top: -277px;
            left: 0;
            transform: translate(0, 0);
            animation: liuxing02 6s ease-in infinite 3s;
            z-index: 0;
        }
        .liuxing-03 {
            width: 282px;
            height: 235px;
            background: url("../../../assets/images/pc/liuxing-03.png") no-repeat;
            position: absolute;
            top: -235px;
            left: 200px;
            transform: translate(0px, -235px);
            animation: liuxing03 4s infinite 6s;
            z-index: 0;
        }
    }
    .home-content {
        width: 1100px;
        height: 3105px;
        background-image: url("../../../assets/images/pc/home-bg.png");
        background-repeat: no-repeat;
        margin: 0 auto;
        .home-content-01 {
            display: flex;
            flex-direction: column;
            position: relative;
            height: 888px;
            width: 100%;
            overflow: hidden;
            .title {
                display: flex;
                justify-content: center;
                position: absolute;
                width: 100%;
                top: 190px;
            }
            .content {
                display: flex;
                justify-content: center;
                position: absolute;
                width: 100%;
                top: 360px;
                .carousel {
                    width: 74%;
                    margin: 0 auto;
                }
                .carousel-content {
                    position: relative;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                }
                .info-item {
                    border: solid 3px #fff;
                    border-radius: 15px;
                    padding: 90px 16px 24px 16px;
                    position: relative;
                    margin: 0 12px;
                    text-align: center;
                    font-size: 16px;
                    color: #fff;
                    font-family: ChalkboardBold;
                    background-color: rgba(229, 148, 41, 0.6);
                    flex: 1;
                    &.disabled {
                        background-color: rgba(117, 117, 117, 0.5);
                    }
                    .icon {
                        width: 188px;
                        height: 188px;
                        /*background-image: url("../../../assets/images/pc/content01-6.png");*/
                        /*background-repeat: no-repeat;*/
                        /*background-position: center center;*/
                        position: absolute;
                        top: -99px;
                        left: 50%;
                        margin-left: -99px;
                        img {
                            width: 100%;
                            height: 100%;
                            position: relative;
                            z-index: 0;
                            &.sold-out {
                                position: absolute;
                                z-index: 1;
                                width: 256px;
                                height: 129px;
                                left: -32px;
                                top: 12px;
                            }
                        }
                    }
                    &.mjolnir {
                        .icon {
                            background-image: url("../../../assets/images/pc/content01-4.png");
                        }
                    }
                    &.rocket {
                        .icon {
                            background-image: url("../../../assets/images/pc/content01-5.png");
                            img {
                                margin-left: -76px;
                                margin-top: -105px;
                            }
                        }
                    }
                    .name {
                        font-size: 26px;
                        white-space: nowrap;
                    }
                    .star {
                        display: flex;
                        justify-content: center;
                        font-size: 36px;
                        font-family: ChalkboardBold;
                    }
                    .item {
                        display: flex;
                        flex-direction: column;
                        padding-bottom: 14px;
                        label {
                            color: rgba(255, 255, 255, 0.4);
                            font-family: Chalkboard;
                            span {
                                font-size: 14px;
                            }
                        }
                        span {
                            font-size: 20px;
                        }
                    }
                    .button {
                        position: absolute;
                        width: 100%;
                        left: 0;
                        bottom: -24px;
                        display: flex;
                        justify-content: center;
                        button {
                            background-color: #2C99F1;
                            width: 150px;
                            height: 42px;
                            border-radius: 11px;
                            color: #fff;
                            font-family: ChalkboardSEBold;
                            font-size: 20px;
                            cursor: pointer;
                            span {
                                line-height: 2rem;
                            }
                        }
                    }
                }
            }
        }
        .home-content-02 {
            display: flex;
            flex-direction: column;
            position: relative;
            height: 1148px;
            width: 100%;
            overflow: hidden;
            .title {
                display: flex;
                justify-content: center;
                position: absolute;
                width: 100%;
                top: 156px;
            }
            .content {
                display: flex;
                justify-content: center;
                position: absolute;
                width: 100%;
                top: 242px;
                flex-direction: column;
                color: #fff;
                .tips {
                    width: 60%;
                    margin: 0 auto;
                    font-size: 18px;
                    p {
                        padding-bottom: 24px;
                        text-align: center;
                    }
                }
                .list {
                    padding-top: 100px;
                    display: flex;
                }
                .item {
                    padding: 24px 36px 0 36px;
                    width: 60%;
                    margin: 0 auto 24px auto;
                    font-size: 18px;
                    position: relative;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    .name {
                        margin: 0 2px 0 106px;
                        display: flex;
                        span {
                            width: 100%;
                            text-align: center;
                            font-size: 24px;
                            font-family: ChalkboardSEBold;
                        }
                        &.right {
                            margin: 0 106px 0 28px;
                        }
                    }
                    .info {
                        margin: 54px 2px 0 106px;
                        &.right {
                            margin: 54px 106px 0 28px;
                        }
                        .li {
                            position: relative;
                            height: 56px;
                            background-color: rgba(229, 148, 41, 0.6);
                            border-radius: 15px;
                            .warp {
                                position: absolute;
                                box-sizing: border-box;
                                display: flex;
                                width: 100%;
                                height: 116px;
                                top: -40px;
                                justify-content: space-between;
                                padding: 0 24px;
                                span {
                                    font-size: 28px;
                                    font-family: ChalkboardSEBold;
                                    line-height: 136px;
                                    overflow: hidden;
                                }
                            }
                        }
                        .center {
                            padding-bottom: 36px;
                            .line {
                                width: 3px;
                                background-color: #000;
                                height: 46px;
                                margin: 16px auto;
                                &.right {
                                    height: 22px;
                                }
                            }
                            .text {
                                color: #000;
                                font-family: ChalkboardSEBold;
                            }
                        }
                        .tip {
                            display: flex;
                            justify-content: space-between;
                            font-size: 16px;
                            padding: 28px 12px 0 12px;
                        }
                    }
                    p {
                        padding-bottom: 24px;
                    }
                }

            }
        }
        .home-content-03 {
            display: flex;
            flex-direction: column;
            position: relative;
            height: 958px;
            width: 100%;
            overflow: hidden;
            .title {
                display: flex;
                justify-content: center;
                position: absolute;
                width: 100%;
                top: 180px;
            }
            .content {
                display: flex;
                justify-content: center;
                position: absolute;
                width: 60%;
                top: 280px;
                flex-wrap: wrap;
                color: #fff;
                left: 50%;
                margin-left: -30%;
                .item {
                    border: solid 3px #fff;
                    border-radius: 13px;
                    padding: 24px;
                    width: 35%;
                    margin: 12px auto 36px auto;
                    font-size: 18px;
                    position: relative;
                    text-align: center;
                    background-color: rgba(229, 148, 41, 0.6);
                    &.disabled {
                        border: solid 3px transparent;
                        opacity: 0.8;
                        .name {
                            background-color: #595959;
                            border: solid 3px #333;
                        }
                        img {
                            opacity: 0.3;
                        }
                    }
                    .name {
                        width: 120px;
                        height: 36px;
                        line-height: 36px;
                        background-color: #2C99F1;
                        position: absolute;
                        left: 50%;
                        top: -18px;
                        margin-left: -60px;
                        border-radius: 11px;
                        border: solid 3px #fff;
                        span {
                            font-size: 20px;
                        }
                    }
                    .icon {
                        width: 120px;
                        height: 120px;
                        /*border: solid 3px #fff;*/
                        border-radius: 50%;
                        margin: 24px auto;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    p {
                        font-size: 16px;
                    }
                }

            }
        }
    }
}
@keyframes train {
    0% {
        transform: translateX(-3544px);
    }
    100% {
        transform: translateX(2600px);
    }
}

@keyframes liuxing01 {
    0% {
        transform: translate(0, 0);
        opacity: 1;
    }
    30% {
        transform: translate(500px, 500px);
        opacity: 0;
    }
    100% {
        transform: translate(1000px, 1000px);
        opacity: 0;
    }
}

@keyframes liuxing02 {
    0% {
        transform: translate(0, 0);
        opacity: 1;
    }
    20% {
        transform: translate(500px, 500px);
        opacity: 0;
    }
    100% {
        transform: translate(600px, 600px);
        opacity: 0;
    }
}

@keyframes liuxing03 {
    0% {
        transform: translate(0, 0);
        opacity: 1;
    }
    40% {
        transform: translate(500px, 500px);
        opacity: 0;
    }
    100% {
        transform: translate(500px, 500px);
        opacity: 0;
    }
}
.home-product-dialog {
    display: flex;
    flex-direction: column;
    .icon {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 188px;
        position: relative;
        img {
            width: 188px;
            height: 188px;
        }
        .buy-count {
            position: absolute;
            right: 0;
            top: 0;
            color: #fff;
            font-size: 24px;
            font-family: ChalkboardSEBold;
        }
    }
    .stake-content {
        color: #fff;
        font-size: 18px;
        text-align: center;
        font-family: ChalkboardSEBold;
        .apr {
            color: #000;
            padding-top: 24px;
            b {
                font-size: 28px;
            }
        }
    }
    .buy-content {
        font-size: 22px;
        text-align: center;
        color: #fff;
        .stc {
            font-family: ChalkboardSEBold;
        }
        .tip {
            font-size: 18px;
        }
        .balance {
            font-family: ChalkboardSEBold;
            padding-top: 12px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.6);
            margin-bottom: -36px;
        }
    }
    .name {
        font-family: ChalkboardSEBold;
        font-size: 24px;
        color: #fff;
        text-align: center;
    }
    .star {
        font-family: ChalkboardSEBold;
        font-size: 36px;
        color: #fff;
        display: flex;
        justify-content: center;
    }
}
</style>
