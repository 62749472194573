import Vue from 'vue'
import {
    Alert,
    Button,
    Card,
    Dialog,
    Form,
    FormItem,
    Menu,
    Select,
    Switch,
    Option,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Tooltip,
    Input,
    Table,
    TableColumn,
    Message,
    MessageBox,
    Tabs,
    TabPane,
    Loading,
    Upload,
    MenuItemGroup,
    MenuItem,
    Submenu,
    Breadcrumb,
    BreadcrumbItem
} from 'element-ui'

Vue.prototype.$ELEMENT = { zIndex: 3000 }
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Alert)
Vue.use(Button)
Vue.use(Card)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Menu)
Vue.component(Select.name, Select)
Vue.use(Switch)
Vue.use(Option)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Tooltip)
Vue.use(Input)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Loading)
Vue.use(Upload)
Vue.use(MenuItemGroup)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.component(MessageBox.name, MessageBox)


Vue.prototype.$message = Message
Vue.prototype.$msgbox = MessageBox
