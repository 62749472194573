<template>
    <div class="home-content-03">
        <div class="title" v-if="$mobile"><img src="../../assets/images/mobile/content03.png" /> </div>
        <div class="title" v-else><img src="../../assets/images/pc/content03.png" /> </div>
        <div class="content">
            <div class="item" v-for="(item, index) in cpd_list" :key="index" :class="item.itemCls">
                <div class="name"><span>{{item.title}}</span></div>
                <div class="icon"><img :src="item.icon" /> </div>
                <div class="info" v-html="item.infoHtml">
                </div>
            </div>
<!--            <div class="item disabled">-->
<!--                <div class="name"><span>{{$t('home.comingSoon')}}</span></div>-->
<!--                <div class="icon"><img src="../../assets/logo/bch.png" /> </div>-->
<!--                <div class="info" v-html="$t('home.yapr')"></div>-->
<!--            </div>-->
<!--            <div class="item disabled">-->
<!--                <div class="name"><span>{{$t('home.comingSoon')}}</span></div>-->
<!--                <div class="icon"><img src="../../assets/logo/wen.png" /></div>-->
<!--                <div class="info">-->
<!--                    <p></p>-->
<!--                    <p></p>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="item disabled">-->
<!--                <div class="name"><span>{{$t('home.comingSoon')}}</span></div>-->
<!--                <div class="icon"><img src="../../assets/logo/wen.png" /></div>-->
<!--                <div class="info">-->
<!--                    <p></p>-->
<!--                    <p></p>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="item disabled">-->
<!--                <div class="name"><span>Coming Soon</span></div>-->
<!--                <div class="icon"><img src="../../assets/logo/bsv.png" /></div>-->
<!--                <div class="info">-->
<!--                    <p>Total Token Allocation:</p>-->
<!--                    <p>$200000 USD worth of BSV</p>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
export default {
    name: 'Content03',
    computed: {
      cpd_list() {
          const poolData = this.$store.state.poolData
          const pools = poolData.map((item) => {
              item.title = this.$t('home.comingSoon')
              item.itemCls = 'disabled'
              item.infoHtml = '<p></p><p></p>'
              if (item.type === 'current') {
                  item.title = this.$t('home.current')
                  item.infoHtml = this.$t('home.content3', [this.$store.state.maxApr, item.tokenCode])
                  item.itemCls = ''
                  if (item.type === 'next') {
                      item.infoHtml = this.$t('home.yapr')
                  }
              }
              if (item.type === 'past') {
                  item.title = this.$t('home.completed')
              }
              return item
          })
          return pools
      }
    },
    data() {
        return {
            list: []
        }
    }
}
</script>

<style lang="less">

</style>
