<template>
    <div class="page-history-tools-mobile">
        <div class="tools-content">
            <history-pools />
            <div class="tools-content-bg"></div>
        </div>
    </div>
</template>

<script>
import historyPools from '../../../components/tools/history-pools'
export default {
    name: 'Home',
    components: {
        historyPools
    }
}
</script>

<style lang="less">
.page-history-tools-mobile {
    display: flex;
    padding: 0 24px;
    width: 100vw;
    flex-direction: column;
    .history-bar {
        /*background-color: #F7B746;*/
        border-radius: 13px;
        margin-bottom: 12px;
        flex: 1;
        /*padding: 0 16px;*/
        .back-button {
            font-size: 24px;
            color: #fff;
            display: flex;
            align-items: center;
            span {
                font-size: 18px;
            }
        }
    }
    .tools-content {
        min-height: 80Vh;
        width: 100%;
        margin: 12px auto 0 auto;
        position: relative;
        .history-pools-container {
            border-radius: 13px;
            width: 100%;
            .history-content {
                display: flex;
                flex-direction: column;
                .history-item {
                    display: flex;
                    /*border-bottom: solid 1px #FDCB5B;*/
                    flex-direction: column;
                    padding: 24px 0;
                    background-color: #F7B746;
                    border-radius: 13px;
                    margin-bottom: 12px;
                    &:last-of-type {
                        border-bottom: none;
                    }
                    .logo {
                        display: flex;
                        justify-content: center;
                        img {
                            width: 100px;
                            height: 100px;
                        }
                    }
                    .content {
                        flex: 1;
                        display: flex;
                        flex-wrap: wrap;
                        padding: 12px 24px;
                        .sub-item {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            label {
                                color: rgba(255, 255, 255, 0.4);
                                padding-right: 12px;
                            }
                            div {
                                font-family: ChalkboardSEBold;
                                color: #fff;
                                font-size: 16px;
                                text-align: right;
                                flex: 1;
                            }
                        }
                    }
                    .buttons {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        button {
                            height: 28px;
                            border-radius: 9px;
                            line-height: 26px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    .empty {
        width: 100%;
        height: 60vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
