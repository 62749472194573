<template>
    <div class="content">
        <div class="token-logo">
            <img :src="$store.state.currentPool.icon" />
        </div>
        <div class="line-01"></div>
        <div class="token-info">
            <h2>{{$t('home.mining', [$store.state.currentPool.tokenCode])}}</h2>
            <div>{{$t('home.apr', [$store.state.maxApr])}}</div>
            <div class="tips" :class="{active: active}">
                <i @click="toggleTips">i</i>
                <div class="tip-content-bg" @click="active = false"></div>
                <div class="tip-content">
                    <h2>{{$t('home.mining', [$store.state.currentPool.tokenCode])}}</h2>
                    <div class="info">{{$t('home.tipInfo', [$store.state.currentPool.tokenCode])}}</div>
                    <div class="date-info">
                        <p><label>{{$t('home.preparingTime')}}:</label><span>{{$store.state.currentPool.tokenCode === 'BCH' ? $t('home.preparingTimeDate1') : $t('home.preparingTimeDate')}}</span></p>
                        <p><label>{{$t('home.campaignPeriod')}}:</label><span>{{$store.state.currentPool.tokenCode === 'BCH' ? $t('home.campaignPeriodDate1') : $t('home.campaignPeriodDate')}}</span></p>
                    </div>
                    <div class="info-item">
                        <div class="item" v-for="(item, index) in cpd_tools" :key="index">
                            <label>{{setName(item)}}</label>
                            <div class="value">{{setApr(item)}}% APR</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="line-02"><span /><span /></div>
        <pool-count-down />
        <div class="line-02" style="height: 180px"><span /><span /></div>
    </div>
</template>

<script>
import poolCountDown from '../common/pool-coundown'
import toolsMixin from "../../mixins/toolsMixin"

export default {
    name: 'Banner',
    mixins: [toolsMixin],
    components: {
        poolCountDown
    },
    data() {
        return {
            active: false
        }
    },
    computed: {
        cpd_tools() {
            return this.$store.state.tools
        }
    },
    methods: {
        toggleTips() {
            this.active = !this.active
        }
    }
}
</script>
