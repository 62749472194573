<template>
    <div class="page-home-mobile">
        <div class="banner">
            <div class="bg1" />
            <div class="bg2" />
            <div class="train" />
            <div class="liuxing-01"></div>
            <div class="liuxing-02"></div>
            <div class="liuxing-03"></div>
            <banner />
        </div>
        <div class="home-content">
            <content01 />
            <content02 />
            <content03 />
        </div>
    </div>
</template>

<script>
import banner from '../../../components/home/banner'
import content01 from '../../../components/home/content-01'
import content02 from '../../../components/home/content-02'
import content03 from '../../../components/home/content-03'
export default {
    name: 'Home',
    components: {
        banner,
        content01,
        content02,
        content03
    },
    mounted() {
        // alert(document.documentElement.clientWidth)
    }
}
</script>

<style lang="less">
.page-home-mobile {
    display: flex;
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
    min-height: 34.25rem;
    background-image: url("../../../assets/images/mobile/banner.png");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: auto 34.25rem;
    .banner {
        height: 34.25rem;
        width: 100%;
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        z-index: 1;
        .content {
            position: relative;
            .token-logo {
                width: 7.5rem;
                height: 7.5rem;
                /*border: solid .18rem #fff;*/
                background-color: #E8B12A;
                border-radius: 50%;
                margin: 0 auto;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .line-01 {
                width: 1.375rem;
                height: 0.75rem;
                background-color: #898989;
                border-style: solid;
                border-color: #000;
                border-width: 0 .18rem;
                margin: 0 auto;
            }
            .token-info {
                border: solid .18rem #fff;
                /*background-color: #E8B12A;*/
                background-color: #E8B12A;
                padding: 0.75rem 1.5rem;
                border-radius: 0.6875rem;
                margin: 0 auto;
                color: #fff;
                font-size: 1.125rem;
                font-weight: bold;
                text-align: center;
                position: relative;
                h2 {
                    text-align: center;
                    font-size: 1.75rem;
                    font-family: ChalkboardBold;
                }
                .tips {
                    position: absolute;
                    right: -60px;
                    top: 24px;
                    display: flex;
                    i {
                        background-color: #2C99F1;
                        border-radius: 50%;
                        width: 36px;
                        height: 36px;
                        font-style: normal;
                        align-items: center;
                        display: flex;
                        justify-content: center;
                        border: solid 3px #fff;
                        overflow: hidden;
                        font-size: 28px;
                    }
                    &.active {
                        .tip-content-bg {
                            display: block;
                        }
                        .tip-content {
                            display: block;
                        }
                    }
                    .tip-content-bg {
                        width: 100vw;
                        height: 100vh;
                        position: fixed;
                        top: 0;
                        left: 0;
                        z-index: 99;
                        display: none;
                    }
                    .tip-content {
                        position: absolute;
                        background-color: #2C99F1;
                        right: 24px;
                        top: -120px;
                        border: solid 3px #fff;
                        border-radius: 13px;
                        padding: 12px 24px;
                        display: none;
                        z-index: 100;
                        width: 80vw;
                        h2 {
                            font-size: 22px;
                        }
                        .info {
                            font-family: Chalkboard;
                            font-size: 16px;
                        }
                        .date-info {
                            p {
                                display: flex;
                                flex-direction: column;
                                padding-top: 16px;
                                label {
                                    font-size: 12px;
                                }
                                span {
                                    font-size: 16px;
                                }
                            }
                        }
                        .info-item {
                            padding: 12px;
                            border: solid 2px #fff;
                            border-radius: 13px;
                            width: 88%;
                            margin: 24px auto 0 auto;
                            .item {
                                display: flex;
                                justify-content: space-between;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
            .count-down {
                background-color: #000;
                margin: 0 auto;
                padding: 0 0.5rem;
                color: #fff;
                .tip {
                    line-height: 2rem;
                    text-align: center;
                    font-size: 1rem;
                }
                .time {
                    background-color: #232323;
                    height: 2.625rem;
                    display: flex;
                    b {
                        font-size: 2rem!important;
                        font-family: ChalkboardSEBold!important;
                    }
                    i {
                        font-size: 1.625rem!important;
                    }
                }
                .time-tip {
                    display: flex;
                    span {
                        flex: 1;
                        text-align: center;
                        line-height: 1.5rem;
                        font-size: 0.75rem;
                    }
                }
            }
            .line-02 {
                width: 100%;
                display: flex;
                justify-content: space-between;
                min-height: 0.75rem;
                &:last-of-type {
                    height: 8.75rem!important;
                }
                span {
                    width: 1.375rem;
                    background-color: #898989;
                    border-style: solid;
                    border-color: #000;
                    border-width: 0 0.1875rem;
                    &:first-of-type {
                        margin-left: 5.125rem;
                    }
                    &:last-of-type {
                        margin-right: 5.125rem;
                    }
                }
            }
        }
        .bg1 {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;
            background-image: url("../../../assets/images/mobile/bg1.png");
            background-repeat: no-repeat;
            background-position: bottom center;
            pointer-events: none;
        }
        .bg2 {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            background-image: url("../../../assets/images/mobile/bg2.png");
            background-repeat: no-repeat;
            background-position: bottom center;
            pointer-events: none;
        }
        .train {
            width: 75.0625rem;
            height: 5rem;
            background-image: url("../../../assets/images/pc/train.png");
            background-repeat:  repeat-x;
            background-position: center right;
            background-size: auto 5rem;
            position: absolute;
            top: 27.9375rem;
            z-index: 2;
            transform: translateX(-75.0625rem);
            animation: trainMobile 10s linear infinite;
        }
        .liuxing-01 {
            width: 215px;
            height: 185px;
            background: url("../../../assets/images/pc/liuxing-01.png") no-repeat;
            position: absolute;
            top: -11.5625rem;
            left: -13.4375rem;
            transform: translate(0, 0);
            animation: liuxing01 5s ease-in infinite;
            z-index: 0;
        }
        .liuxing-02 {
            width: 19.25rem;
            height: 17.3125rem;
            background: url("../../../assets/images/pc/liuxing-02.png") no-repeat;
            position: absolute;
            top: -17.3125rem;
            left: 0;
            transform: translate(0, 0);
            animation: liuxing02 6s ease-in infinite 3s;
            z-index: 0;
        }
        .liuxing-03 {
            width: 17.625rem;
            height: 14.6875rem;
            background: url("../../../assets/images/pc/liuxing-03.png") no-repeat;
            position: absolute;
            top: -14.6875rem;
            left: 12.5rem;
            transform: translate(0px, -14.6875rem);
            animation: liuxing03 4s infinite 6s;
            z-index: 0;
        }
    }
    .home-content {
        width: 414px;
        background-image: url("../../../assets/images/mobile/home-bg.png");
        background-repeat: no-repeat;
        background-size: 414px auto;
        background-position: top center;
        margin: 0 auto;
        .home-content-01 {
            display: flex;
            flex-direction: column;
            position: relative;
            height: 39rem;
            width: 100%;
            overflow: hidden;
            .title {
                display: flex;
                justify-content: center;
                position: absolute;
                width: 100%;
                top: 4rem;
                img {
                    width: 50%;
                    height: 50%;
                }
            }
            .left-button {
                position: absolute;
                left: 0;
                top: 20rem;
                cursor: pointer;
                opacity: 0.6;
                z-index: 99;
                img {
                    width: 60%;
                }
                &:hover {
                    opacity: 1;
                }
            }
            .right-button {
                position: absolute;
                right: 0;
                top: 20rem;
                transform: rotate(180deg);
                cursor: pointer;
                opacity: 0.6;
                z-index: 99;
                img {
                    width: 60%;
                }
                &:hover {
                    opacity: 1;
                }
            }
            .content {
                display: flex;
                justify-content: center;
                position: absolute;
                width: 100%;
                top: 110px;
                .content-bg {
                    box-sizing: border-box;
                    background-color: rgba(255, 255, 255, 0.3);
                    position: absolute;
                    top: 6.6rem;
                    left: 0;
                    bottom: 5.2rem;
                    width: 100%;
                    border-radius: 0.9375rem 0 0 0.9375rem;
                    z-index: 0;
                    display: none;
                }
                .carousel {
                    width: 70%;
                    overflow: hidden;
                    margin: 0 auto;
                    padding: 4rem 0 1rem 0;
                }
                .carousel-content {
                    box-sizing: border-box;
                    width: 300%;
                    display: flex;
                    margin-bottom: 1rem;
                    transform: translateX(0);
                    transition: transform 0.6s;
                    &.carousel-01 {
                        transform: translateX(0);
                    }
                    &.carousel-02 {
                        transform: translateX(-33.33%);
                    }
                    &.carousel-03 {
                        transform: translateX(-66.66%);
                    }
                }
                .info-item {
                    box-sizing: border-box;
                    border: solid 3px #fff;
                    border-radius: 15px;
                    padding: 70px 0 24px 0;
                    position: relative;
                    margin: 0 12px;
                    text-align: center;
                    font-size: 16px;
                    flex: 1;
                    color: #fff;
                    font-family: ChalkboardBold;
                    background-color: rgba(229, 148, 41, 0.6);
                    &.disabled {
                        background-color: rgba(117, 117, 117, 0.5);
                    }
                    .icon {
                        width: 150px;
                        height: 150px;
                        /*background-image: url("../../../assets/images/pc/content01-6.png");*/
                        /*background-repeat: no-repeat;*/
                        /*background-position: center center;*/
                        position: absolute;
                        top: -75px;
                        left: 50%;
                        margin-left: -75px;
                        img {
                            width: 100%;
                            height: 100%;
                            position: relative;
                            z-index: 0;
                            &.sold-out {
                                position: absolute;
                                z-index: 1;
                                width: 9.6rem;
                                height: 4.8375rem;
                                left: 50%;
                                margin-left: -4.8rem;
                                top: 28px;
                            }
                        }
                    }
                    .name {
                        font-size: 24px;
                    }
                    .star {
                        display: flex;
                        justify-content: center;
                        font-size: 24px;
                        font-family: ChalkboardBold;
                    }
                    .item {
                        display: flex;
                        flex-direction: column;
                        padding-bottom: 10px;
                        &.count {
                            padding: 0 12px 6px 12px;
                        }
                        label {
                            color: rgba(255, 255, 255, 0.4);
                            font-family: Chalkboard;
                            white-space: nowrap;
                            span {
                                font-size: 14px;
                            }
                        }
                        span {
                            font-size: 18px;
                        }
                    }
                    .button {
                        position: absolute;
                        width: 100%;
                        left: 0;
                        bottom: -24px;
                        display: flex;
                        justify-content: center;
                        button {
                            background-color: #2C99F1;
                            width: 150px;
                            height: 42px;
                            border-radius: 11px;
                            color: #fff;
                            font-family: ChalkboardSEBold;
                            font-size: 20px;
                            cursor: pointer;
                            span {
                                line-height: 2rem;
                            }
                        }
                    }
                }
            }
        }
        .home-content-02 {
            display: flex;
            flex-direction: column;
            position: relative;
            height: 70rem;
            width: 100%;
            overflow: hidden;
            .title {
                display: flex;
                justify-content: center;
                position: absolute;
                width: 100%;
                top: 4rem;
                img {
                    width: 40%;
                    height: 40%;
                }
            }
            .content {
                display: flex;
                justify-content: center;
                position: absolute;
                width: 100%;
                top: 7rem;
                flex-direction: column;
                color: #fff;
                .tips {
                    width: 60%;
                    margin: 0 auto;
                    font-size: 1.125rem;
                    min-height: 16rem;
                    p {
                        padding-bottom: 1rem;
                        text-align: center;
                    }
                }
                .list {
                    padding-top: 1.6rem;
                    display: flex;
                    flex-direction: column;
                }
                .item {
                    padding: 1rem;
                    width: 90%;
                    margin: 0 auto 1.5rem auto;
                    font-size: 1.125rem;
                    position: relative;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    .name {
                        display: flex;
                        justify-content: center;
                        span {
                            width: 100%;
                            text-align: center;
                            font-size: 1.5rem;
                            font-family: ChalkboardSEBold;
                        }
                    }
                    .info {
                        margin: 2rem 1rem 0 1rem;
                        .li {
                            position: relative;
                            height: 2rem;
                            background-color: rgba(229, 148, 41, 0.6);
                            border-radius: 0.5rem;
                            .warp {
                                position: absolute;
                                box-sizing: border-box;
                                display: flex;
                                width: 100%;
                                top: -2rem;
                                justify-content: space-between;
                                padding: 0 0.7rem  0 0.7rem;
                                div {
                                    overflow: hidden;
                                    img {
                                        width: 5rem;
                                    }
                                }
                                span {
                                    font-size: 1.375rem;
                                    font-family: ChalkboardSEBold;
                                    line-height: 400%;
                                    overflow: hidden;
                                }
                            }
                        }
                        .center {
                            padding-bottom: 1.5rem;
                            .line {
                                width: 0.1875rem;
                                background-color: #000;
                                height: 1.5rem;
                                margin: .6rem auto .3rem auto;
                                &.right {
                                    height: 1.375rem;
                                }
                            }
                            .text {
                                color: #000;
                                font-family: ChalkboardSEBold;
                                font-size: 1rem;
                            }
                        }
                        .tip {
                            display: flex;
                            justify-content: space-between;
                            font-size: 1rem;
                            padding: 1.75rem 0.75rem 0 0.75rem;
                        }
                    }
                    p {
                        padding-bottom: 1.5rem;
                    }
                }
            }
        }
        .home-content-03 {
            display: flex;
            flex-direction: column;
            position: relative;
            height: 40rem;
            width: 100%;
            overflow: hidden;
            .title {
                display: flex;
                justify-content: center;
                position: absolute;
                width: 100%;
                top: 4.4rem;
                img {
                    width: 30%;
                    height: 30%;
                }
            }
            .content {
                display: flex;
                justify-content: center;
                position: absolute;
                width: 90%;
                top: 7rem;
                flex-wrap: wrap;
                color: #fff;
                left: 50%;
                margin-left: -45%;
                .item {
                    border: solid 0.1875rem #fff;
                    border-radius: 0.8125rem;
                    padding: 0.8rem 0;
                    width: 45%;
                    margin: 1rem auto 0.5rem auto;
                    font-size: 1rem;
                    position: relative;
                    text-align: center;
                    background-color: rgba(229, 148, 41, 0.6);
                    &.disabled {
                        border: solid 0.1875rem transparent;
                        opacity: 0.8;
                        min-height: 9rem;
                        .name {
                            background-color: #595959;
                            border: solid 0.1875rem #333;
                        }
                        img {
                            opacity: 0.3;
                        }
                    }
                    .name {
                        width: 70%;
                        height: 2rem;
                        line-height: 1.6rem;
                        background-color: #2C99F1;
                        position: absolute;
                        left: 50%;
                        top: -1rem;
                        margin-left: -35%;
                        border-radius: 0.8125rem;
                        border: solid 0.1375rem #fff;
                        span {
                            font-size: 1.125rem;
                        }
                    }
                    .icon {
                        width: 4rem;
                        height: 4rem;
                        /*border: solid 0.1875rem #fff;*/
                        border-radius: 50%;
                        margin: .4rem auto;
                    }
                    p {
                        font-size: 1rem;
                        letter-spacing: 0px;
                    }
                }

            }
        }
    }
}
@keyframes trainMobile {
    0% {
        transform: translateX(-75.0625rem);
    }
    100% {
        transform: translateX(62.5rem);
    }
}

@keyframes liuxing01 {
    0% {
        transform: translate(0, 0);
        opacity: 1;
    }
    30% {
        transform: translate(31.25rem, 31.25rem);
        opacity: 0;
    }
    100% {
        transform: translate(62.5rem, 62.5rem);
        opacity: 0;
    }
}

@keyframes liuxing02 {
    0% {
        transform: translate(0, 0);
        opacity: 1;
    }
    20% {
        transform: translate(31.25rem, 31.25rem);
        opacity: 0;
    }
    100% {
        transform: translate(37.5rem, 37.5rem);
        opacity: 0;
    }
}

@keyframes liuxing03 {
    0% {
        transform: translate(0, 0);
        opacity: 1;
    }
    40% {
        transform: translate(31.25rem, 31.25rem);
        opacity: 0;
    }
    100% {
        transform: translate(31.25rem, 31.25rem);
        opacity: 0;
    }
}
.home-product-dialog {
    display: flex;
    flex-direction: column;
    .icon {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 188px;
        img {
            width: 188px;
            height: 188px;
        }
        .buy-count {
            position: absolute;
            right: 0;
            top: 0;
            color: #fff;
            font-size: 24px;
            font-family: ChalkboardSEBold;
        }
    }
    .name {
        font-family: ChalkboardSEBold;
        font-size: 24px;
        color: #fff;
        text-align: center;
    }
    .star {
        font-family: ChalkboardSEBold;
        font-size: 36px;
        color: #fff;
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 375px) {
    .page-home-mobile {
        .home-content {
            width: 360px;
            ackground-image: url("../../../assets/images/mobile/home-bg.png");
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: top center;
            margin: 0 auto;
            .home-content-01 {
                position: relative;
                height: 34.4rem;
                .title {
                    top: 4rem;
                }
                .content {
                    display: flex;
                    justify-content: center;
                    position: absolute;
                    width: 100%;
                    top: 90px;
                    .content-bg {
                        box-sizing: border-box;
                        background-color: rgba(255, 255, 255, 0.3);
                        position: absolute;
                        top: 6.6rem;
                        left: 0;
                        bottom: 5.2rem;
                        width: 100%;
                        border-radius: 0.9375rem 0 0 0.9375rem;
                        z-index: 0;
                        display: none;
                    }
                    .carousel {
                        width: 70%;
                        overflow: hidden;
                        margin: 0 auto;
                        padding: 4rem 0 1rem 0;
                    }
                    .carousel-content {
                        box-sizing: border-box;
                        width: 300%;
                        display: flex;
                        margin-bottom: 1rem;
                        transform: translateX(0);
                        transition: transform 0.6s;
                        &.carousel-01 {
                            transform: translateX(0);
                        }
                        &.carousel-02 {
                            transform: translateX(-33.33%);
                        }
                        &.carousel-03 {
                            transform: translateX(-66.66%);
                        }
                    }
                    .info-item {
                        border: solid 3px #fff;
                        border-radius: 15px;
                        padding: 70px 16px 24px 16px;
                        position: relative;
                        margin: 0 12px;
                        text-align: center;
                        font-size: 16px;
                        color: #fff;
                        font-family: ChalkboardBold;
                        background-color: rgba(229, 148, 41, 0.6);
                        &.disabled {
                            background-color: rgba(117, 117, 117, 0.5);
                        }
                        .icon {
                            width: 130px;
                            height: 130px;
                            /*background-image: url("../../../assets/images/pc/content01-6.png");*/
                            /*background-repeat: no-repeat;*/
                            /*background-position: center center;*/
                            position: absolute;
                            top: -65px;
                            left: 50%;
                            margin-left: -65px;
                            img {
                                width: 100%;
                                height: 100%;
                                position: relative;
                                z-index: 0;
                                &.sold-out {
                                    position: absolute;
                                    z-index: 1;
                                    width: 9.6rem;
                                    height: 4.8375rem;
                                    left: 50%;
                                    margin-left: -4.8rem;
                                    top: 28px;
                                }
                            }
                        }
                        .name {
                            font-size: 20px;
                        }
                        .star {
                            display: flex;
                            justify-content: center;
                            font-size: 20px;
                            font-family: ChalkboardBold;
                        }
                        .item {
                            display: flex;
                            flex-direction: column;
                            padding-bottom: 4px;
                            label {
                                color: rgba(255, 255, 255, 0.4);
                                font-family: Chalkboard;
                                white-space: nowrap;
                                span {
                                    font-size: 14px;
                                }
                            }
                            span {
                                font-size: 16px;
                            }
                        }
                        .button {
                            position: absolute;
                            width: 100%;
                            left: 0;
                            bottom: -24px;
                            display: flex;
                            justify-content: center;
                            button {
                                background-color: #2C99F1;
                                width: 150px;
                                height: 42px;
                                border-radius: 11px;
                                color: #fff;
                                font-family: ChalkboardSEBold;
                                font-size: 20px;
                                cursor: pointer;
                                span {
                                    line-height: 2rem;
                                }
                            }
                        }
                    }
                }
            }
            .home-content-02 {
                display: flex;
                flex-direction: column;
                position: relative;
                height: 64rem;
                width: 100%;
                overflow: hidden;
                .title {
                    display: flex;
                    justify-content: center;
                    position: absolute;
                    width: 100%;
                    top: 3rem;
                    img {
                        width: 40%;
                        height: 40%;
                    }
                }
                .content {
                    display: flex;
                    justify-content: center;
                    position: absolute;
                    width: 100%;
                    top: 6rem;
                    flex-direction: column;
                    color: #fff;
                    .tips {
                        width: 80%;
                        height: 13.4rem;
                        overflow: hidden;
                        margin: 0 auto;
                        font-size: 1.125rem;
                        min-height: 14rem;
                        p {
                            padding-bottom: 1rem;
                            text-align: center;
                        }
                    }
                    .list {
                        padding-top: 1rem;
                        display: flex;
                        flex-direction: column;
                    }
                    .item {
                        padding: 1rem;
                        width: 100%;
                        margin: 0 auto 1.5rem auto;
                        font-size: 1.125rem;
                        position: relative;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        .name {
                            display: flex;
                            justify-content: center;
                            span {
                                width: 100%;
                                text-align: center;
                                font-size: 1.5rem;
                                font-family: ChalkboardSEBold;
                            }
                        }
                        .info {
                            margin: 1rem 1rem 0 1rem;
                            .li {
                                position: relative;
                                height: 1.6rem;
                                background-color: rgba(229, 148, 41, 0.6);
                                border-radius: 0.5rem;
                                .warp {
                                    position: absolute;
                                    box-sizing: border-box;
                                    display: flex;
                                    width: 100%;
                                    top: -2rem;
                                    justify-content: space-between;
                                    padding: 0 0.7rem  0 0.7rem;
                                    div {
                                        overflow: hidden;
                                        img {
                                            width: 5rem;
                                        }
                                    }
                                    span {
                                        font-size: 1.375rem;
                                        font-family: ChalkboardSEBold;
                                        line-height: 400%;
                                        overflow: hidden;
                                    }
                                }
                            }
                            .center {
                                padding-bottom: 1.5rem;
                                .line {
                                    width: 0.1875rem;
                                    background-color: #000;
                                    height: 1.5rem;
                                    margin: .6rem auto .3rem auto;
                                    &.right {
                                        height: 1.375rem;
                                    }
                                }
                                .text {
                                    color: #000;
                                    font-family: ChalkboardSEBold;
                                    font-size: 1rem;
                                }
                            }
                            .tip {
                                display: flex;
                                justify-content: space-between;
                                font-size: 1rem;
                                padding: 1.75rem 0.75rem 0 0.75rem;
                            }
                        }
                        p {
                            padding-bottom: 1.5rem;
                        }
                    }
                }
            }
            .home-content-03 {
                display: flex;
                flex-direction: column;
                position: relative;
                height: 30rem;
                width: 100%;
                overflow: hidden;
                .title {
                    display: flex;
                    justify-content: center;
                    position: absolute;
                    width: 100%;
                    top: 0.5rem;
                    img {
                        width: 30%;
                        height: 30%;
                    }
                }
                .content {
                    display: flex;
                    justify-content: center;
                    position: absolute;
                    width: 90%;
                    top: 3.4rem;
                    flex-wrap: wrap;
                    color: #fff;
                    left: 50%;
                    margin-left: -45%;
                    .item {
                        border: solid 0.1375rem #fff;
                        border-radius: 0.8125rem;
                        padding: 0.7rem 0 0.3rem 0;
                        width: 46%;
                        margin: 0.2rem auto 0.9rem auto;
                        font-size: 1rem;
                        position: relative;
                        text-align: center;
                        background-color: rgba(229, 148, 41, 0.6);
                        .name {
                            width: 70%;
                            height: 1.8rem;
                            line-height: 1.6rem;
                            background-color: #2C99F1;
                            position: absolute;
                            left: 50%;
                            top: -1rem;
                            margin-left: -35%;
                            span {
                                font-size: 1rem;
                                line-height: 1rem;
                            }
                        }
                        .icon {
                            width: 3.6rem;
                            height: 3.6rem;
                            /*border: solid 0.1875rem #fff;*/
                            border-radius: 50%;
                            margin: .5rem auto .2rem auto;
                        }
                        p {
                            font-size: 1rem;
                            letter-spacing: 0px;
                            font-size: 14px;
                        }
                    }

                }
            }
        }
    }
}
</style>
